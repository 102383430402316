import React, { useRef } from "react";
import Slider from "react-slick";
import { pressSlider } from "./common/Helper";
import { NextArow, PrevArow } from "./common/Icons";
import TriangleImg from "../assets/images/png/about-triangle-img.png";
import TriangleImgBorder from "../assets/images/png/about-triangle-border-img.png";
import SquareImg from "../assets/images/png/about-square-img-border.png";
import SquareImgBorder from "../assets/images/png/about-square-img.png";

const PressRelease = () => {
  const sliderRef = useRef(null);
  const settings = {
    pauseOnHover: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 1023.98,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 639.98,
        settings: {
          slidesToShow: 1.4,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 449.98,
        settings: {
          slidesToShow: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <div className="relative" id="remove_slider_dots">
      <img
        className="absolute right-[11%] bottom-[-6%] lg:right-[2%] lg:bottom-[-8%] z-10 animate-[wiggle_infinite_linear_2s]"
        src={TriangleImg}
        alt="triangle-img"
      />
      <img
        className="absolute right-[11%] bottom-[-7%] lg:right-[1.6%] lg:bottom-[-9.5%] "
        src={TriangleImgBorder}
        alt="triangle-img"
      />
      <img
        className="absolute left-[3%] top-[-2%] circel_animate"
        src={SquareImgBorder}
        alt="triangle-img"
      />
      <img
        className="absolute left-[3%] top-[-2%]"
        src={SquareImg}
        alt="triangle-img"
      />
      <div id="press" className="md:max-w-[736px] lg:max-w-[1140px] mx-auto px-5 md:px-10 xl:px-0 relative my-[64px] lg:my-[150px]">
        <h2 className="text-black20 text-[32px] md:text-lg font-medium ff_inter text-center mb-5 md:mb-[54px] ">
          Press <span className="text-red">Releases</span>
        </h2>
        <div className="mx-sm-5 relative">
          <Slider {...settings} ref={sliderRef}>
            {pressSlider.map((obj, index) => (
              <div className="px-2 sm:px-3 py-4" key={index}>
                <div className="p-[14px] bg-white border border-solid rounded-xl transition-all ease-linear duration-300 hover:shadow-[0px_7px_23px_rgba(193,193,193,0.25);] hover:border-transparent">
                  <img src={obj.pressImg} alt="sliderImg" />
                  <div className="flex justify-between mt-5 mb-4">
                    <div className="flex">
                      <img src={obj.calendarImg} alt="calendarImg" />
                      <p className="ff_inter ml-2 text-[#4d4d4d] font-normal text-xs">
                        {obj.date}
                      </p>
                    </div>
                    <div className="flex">
                      {/* <img src={obj.commentImg} alt="calendarImg" /> */}
                      <p className="ff_inter ml-2 text-[#4d4d4d] font-normal text-xs">
                        {/* {obj.comment} */}
                      </p>
                    </div>
                  </div>
                  <p className="font-medium text-sm lg:text-md ff_inter text-black mb-1">
                    {obj.loremBig}
                  </p>
                  <p className="font-normal text-xs lg:text-base ff_inter text-[#4d4d4d] leading-[174%]">
                    {obj.loremPara}
                  </p>
                  <a 
                    href= {obj.link}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                  <button className="font-normal leading-[19px] text-base ff_inter text-black3a py-[10px] px-[23px] rounded-[100px] border border-[rgba(0,0,0,0.3)] mt-5  duration-500 hover:bg-[#CF1B1B] hover:text-white">
                    {obj.readBtn}
                  </button>
                  </a>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div>
          <span
            className="cursor-pointer prev_arrow top-[58%] absolute left-[-1%] lg:left-[1%] xl:left-[-5%] hidden md:block w-[33px] xl:w-[46px]"
            onClick={() => sliderRef.current.slickPrev()}
          >
            <PrevArow />
          </span>
          <span
            className="cursor-pointer ms-3 next_arrow top-[58%] absolute right-[-1%] lg:right-[0%] xl:right-[-5%] hidden md:block w-[33px] xl:w-[46px]"
            onClick={() => sliderRef.current.slickNext()}
          >
            <NextArow />
          </span>
        </div>
      </div>
    </div>
  );
};
export default PressRelease;
