export const CrossIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 16 16"
    >
      <path
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="m11.25 4.75l-6.5 6.5m0-6.5l6.5 6.5"
      />
    </svg>
  );
};
export const HeaderCrossIcons = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      fill="white"
      className="bi bi-x-lg"
      viewBox="0 0 16 16"
    >
      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
    </svg>
  );
};
export const ToggleIcon = () => {
  return (
    <svg
      className="block h-10 w-10"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 6h16M4 12h16M4 18h16"
      />
    </svg>
  );
};
export const PhoneIcon = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.407928"
        width="32"
        height="32"
        rx="16"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M21.9625 23.6579C22.1875 23.6579 22.375 23.5829 22.525 23.4329C22.675 23.2829 22.75 23.0954 22.75 22.8704V19.8329C22.75 19.6579 22.6938 19.5047 22.5813 19.3732C22.4688 19.2422 22.325 19.1579 22.15 19.1204L19.5625 18.5954C19.3875 18.5704 19.2093 18.5859 19.0278 18.6419C18.8468 18.6984 18.7 18.7829 18.5875 18.8954L16.825 20.6579C15.875 20.0829 15.0062 19.4017 14.2188 18.6142C13.4312 17.8267 12.775 16.9829 12.25 16.0829L14.05 14.2454C14.1625 14.1329 14.2345 14.0047 14.266 13.8607C14.297 13.7172 14.3 13.5579 14.275 13.3829L13.7875 10.7579C13.7625 10.5829 13.6813 10.4392 13.5438 10.3267C13.4062 10.2142 13.25 10.1579 13.075 10.1579H10.0375C9.8125 10.1579 9.625 10.2329 9.475 10.3829C9.325 10.5329 9.25 10.7204 9.25 10.9454C9.25 12.5579 9.6095 14.1297 10.3285 15.6607C11.047 17.1922 11.997 18.5484 13.1785 19.7294C14.3595 20.9109 15.7155 21.8609 17.2465 22.5794C18.778 23.2984 20.35 23.6579 21.9625 23.6579Z"
        fill="white"
      />
    </svg>
  );
};
export const TymerIcon = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.407928"
        width="32"
        height="32"
        rx="16"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M15.9925 9.40793C11.8525 9.40793 8.5 12.7679 8.5 16.9079C8.5 21.0479 11.8525 24.4079 15.9925 24.4079C20.14 24.4079 23.5 21.0479 23.5 16.9079C23.5 12.7679 20.14 9.40793 15.9925 9.40793ZM18.4675 20.4404L15.25 17.2154V13.1579H16.75V16.6004L19.5325 19.3829L18.4675 20.4404Z"
        fill="white"
      />
    </svg>
  );
};
export const LocationIcon = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.407928"
        width="32"
        height="32"
        rx="16"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M16 16.9079C16.4125 16.9079 16.7657 16.7609 17.0597 16.4669C17.3532 16.1734 17.5 15.8204 17.5 15.4079C17.5 14.9954 17.3532 14.6422 17.0597 14.3482C16.7657 14.0547 16.4125 13.9079 16 13.9079C15.5875 13.9079 15.2345 14.0547 14.941 14.3482C14.647 14.6422 14.5 14.9954 14.5 15.4079C14.5 15.8204 14.647 16.1734 14.941 16.4669C15.2345 16.7609 15.5875 16.9079 16 16.9079ZM16 24.1267C15.9 24.1267 15.8 24.1079 15.7 24.0704C15.6 24.0329 15.5125 23.9829 15.4375 23.9204C13.6125 22.3079 12.25 20.8112 11.35 19.4302C10.45 18.0487 10 16.7579 10 15.5579C10 13.6829 10.6033 12.1892 11.8097 11.0767C13.0157 9.96418 14.4125 9.40793 16 9.40793C17.5875 9.40793 18.9842 9.96418 20.1902 11.0767C21.3967 12.1892 22 13.6829 22 15.5579C22 16.7579 21.55 18.0487 20.65 19.4302C19.75 20.8112 18.3875 22.3079 16.5625 23.9204C16.4875 23.9829 16.4 24.0329 16.3 24.0704C16.2 24.1079 16.1 24.1267 16 24.1267Z"
        fill="white"
      />
    </svg>
  );
};
export const SearchIcon = () => {
  return (
    <svg
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.4395 27.7406L23.5856 21.8858C24.529 20.5894 25.0365 19.027 25.0349 17.4237C25.0349 13.2273 21.6207 9.81299 17.4242 9.81299C13.2277 9.81299 9.81348 13.2273 9.81348 17.4237C9.81348 21.6202 13.2277 25.0345 17.4242 25.0345C19.0275 25.036 20.5899 24.5285 21.8863 23.5851L27.7411 29.439L29.4395 27.7406ZM17.4242 22.6307C16.3942 22.6308 15.3873 22.3255 14.5309 21.7533C13.6745 21.1811 13.0069 20.3679 12.6127 19.4163C12.2185 18.4647 12.1154 17.4176 12.3163 16.4074C12.5172 15.3972 13.0132 14.4693 13.7415 13.741C14.4698 13.0127 15.3977 12.5167 16.4079 12.3158C17.4181 12.1149 18.4652 12.218 19.4168 12.6122C20.3683 13.0064 21.1816 13.674 21.7538 14.5304C22.326 15.3869 22.6313 16.3937 22.6312 17.4237C22.6296 18.8042 22.0805 20.1277 21.1043 21.1038C20.1282 22.08 18.8047 22.6291 17.4242 22.6307Z"
        fill="white"
      />
    </svg>
  );
};
export const SlashIcon = () => {
  return (
    <svg
      width="3"
      height="29"
      viewBox="0 0 3 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        opacity="0.6"
        x1="1.44907"
        y1="0.478485"
        x2="1.44907"
        y2="28.3374"
        stroke="white"
        strokeWidth="1.26631"
      />
    </svg>
  );
};
export const ShopsyIcon = () => {
  return (
    <svg
      width="40"
      height="39"
      viewBox="0 0 40 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4634 29.439C12.8637 29.439 12.3505 29.247 11.9238 28.863C11.4964 28.4783 11.2827 28.0161 11.2827 27.4764V15.7008C11.2827 15.1611 11.4964 14.6992 11.9238 14.3152C12.3505 13.9305 12.8637 13.7382 13.4634 13.7382H15.644C15.644 12.6588 16.0711 11.7347 16.9252 10.966C17.7793 10.1973 18.806 9.81299 20.0054 9.81299C21.2047 9.81299 22.2315 10.1973 23.0856 10.966C23.9397 11.7347 24.3667 12.6588 24.3667 13.7382H26.5474C27.1471 13.7382 27.6606 13.9305 28.088 14.3152C28.5147 14.6992 28.728 15.1611 28.728 15.7008V27.4764C28.728 28.0161 28.5147 28.4783 28.088 28.863C27.6606 29.247 27.1471 29.439 26.5474 29.439H13.4634ZM16.7344 18.6447C17.0433 18.6447 17.3024 18.5505 17.5118 18.3621C17.7204 18.1743 17.8247 17.9414 17.8247 17.6634V15.7008H15.644V17.6634C15.644 17.9414 15.7487 18.1743 15.9581 18.3621C16.1667 18.5505 16.4255 18.6447 16.7344 18.6447ZM17.8247 13.7382H22.186C22.186 13.1985 21.9727 12.7366 21.546 12.3526C21.1186 11.9679 20.6051 11.7756 20.0054 11.7756C19.4057 11.7756 18.8925 11.9679 18.4658 12.3526C18.0384 12.7366 17.8247 13.1985 17.8247 13.7382ZM23.2764 18.6447C23.5853 18.6447 23.8444 18.5505 24.0538 18.3621C24.2624 18.1743 24.3667 17.9414 24.3667 17.6634V15.7008H22.186V17.6634C22.186 17.9414 22.2907 18.1743 22.5001 18.3621C22.7087 18.5505 22.9674 18.6447 23.2764 18.6447Z"
        fill="white"
      />
    </svg>
  );
};
export const NextArow = () => {
  return (
    <>
      <svg
        width="50"
        height="50"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="19" stroke="#ce1b1b" strokeWidth="1" />
        <g clipPath="url(#clip0_403_26)">
          <path
            d="M19.4491 12.7085L26.7613 19.999C26.8481 20.0858 26.9097 20.1798 26.9462 20.2811C26.9826 20.3824 27.0006 20.4908 27 20.6066C27 20.7223 26.9818 20.8308 26.9453 20.932C26.9089 21.0333 26.8475 21.1273 26.7613 21.2141L19.4491 28.5263C19.2466 28.7288 18.9935 28.8301 18.6897 28.8301C18.3859 28.8301 18.1255 28.7216 17.9086 28.5046C17.6916 28.2876 17.5831 28.0345 17.5831 27.7452C17.5831 27.4559 17.6916 27.2027 17.9086 26.9858L24.2877 20.6066L17.9086 14.2274C17.706 14.0249 17.6048 13.7752 17.6048 13.4784C17.6048 13.1815 17.7133 12.9249 17.9303 12.7085C18.1472 12.4915 18.4004 12.3831 18.6897 12.3831C18.979 12.3831 19.2321 12.4915 19.4491 12.7085Z"
            fill="#ce1b1b"
          />
        </g>
        <defs>
          <clipPath id="clip0_403_26">
            <rect
              width="26.6667"
              height="26.6667"
              fill="white"
              transform="translate(6 6.66675)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
export const PrevArow = () => {
  return (
    <>
      <svg
        width="50"
        height="50"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="19" fill="none" />
        <circle cx="20" cy="20" r="19" fill="none" />
        <circle cx="20" cy="20" r="19" stroke="#ce1b1b" strokeWidth="1" />
        <path
          d="M22.8839 28.1216L15.5717 20.8311C15.4849 20.7443 15.4233 20.6502 15.3868 20.549C15.3504 20.4477 15.3324 20.3392 15.333 20.2235C15.333 20.1078 15.3512 19.9993 15.3877 19.898C15.4242 19.7968 15.4855 19.7028 15.5717 19.616L22.8839 12.3038C23.0864 12.1013 23.3396 12 23.6433 12C23.9471 12 24.2075 12.1085 24.4244 12.3255C24.6414 12.5424 24.7499 12.7956 24.7499 13.0849C24.7499 13.3742 24.6414 13.6273 24.4244 13.8443L18.0453 20.2235L24.4244 26.6027C24.627 26.8052 24.7282 27.0549 24.7282 27.3517C24.7282 27.6485 24.6197 27.9052 24.4028 28.1216C24.1858 28.3385 23.9326 28.447 23.6433 28.447C23.354 28.447 23.1009 28.3385 22.8839 28.1216Z"
          fill="#ce1b1b"
        />
        <defs>
          <linearGradient
            id="paint0_linear_402_7"
            x1="20"
            y1="0"
            x2="20"
            y2="40"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B8BABD" />
            <stop offset="1" stopColor="#727A7E" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};
export const Footerlogo = () => {
  return (
    <>
      <svg
        className="w-[180px] sm:w-[239px] cursor-pointer"
        width="239"
        height="66"
        viewBox="0 0 239 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M80.7048 45.3169C81.3771 45.8858 81.7133 46.6745 81.7133 47.6701C81.7133 48.6656 81.3771 49.4543 80.7048 50.0362C80.0325 50.618 79.1274 50.9024 77.9896 50.9024H75.1839V55.4407H74.3564V44.4765H77.9896C79.1274 44.4636 80.0325 44.748 80.7048 45.3169ZM80.0713 49.4802C80.5755 49.0535 80.8212 48.4588 80.8212 47.6701C80.8212 46.8943 80.5755 46.2995 80.0713 45.8729C79.567 45.4462 78.843 45.2393 77.8862 45.2393H75.1839V50.1267H77.8862C78.843 50.1137 79.567 49.9069 80.0713 49.4802Z"
          fill="white"
        />
        <path
          d="M93.4273 51.4324C93.4273 52.7899 93.0653 53.8114 92.3283 54.5354C91.5913 55.2465 90.557 55.6086 89.1994 55.6086C87.8418 55.6086 86.8074 55.2465 86.0704 54.5354C85.3335 53.8243 84.9714 52.7899 84.9714 51.4324V44.4634H85.7989V51.2643C85.7989 52.4408 86.0834 53.333 86.6652 53.9277C87.247 54.5354 88.0874 54.8328 89.2123 54.8328C90.3242 54.8328 91.1646 54.5354 91.7335 53.9277C92.3154 53.32 92.5998 52.4408 92.5998 51.2643V44.4634H93.4273V51.4324Z"
          fill="white"
        />
        <path
          d="M97.7457 44.4634H101.625C102.827 44.4634 103.719 44.709 104.314 45.2133C104.909 45.7175 105.206 46.4287 105.206 47.3725C105.206 48.019 105.038 48.562 104.689 49.0016C104.34 49.4412 103.848 49.7386 103.202 49.8679V49.8808C103.913 49.9972 104.469 50.2816 104.883 50.7471C105.297 51.2126 105.503 51.7944 105.503 52.4796C105.503 53.4235 105.167 54.1475 104.495 54.6647C103.823 55.1819 102.866 55.4405 101.625 55.4405H97.7457V44.4634ZM101.637 49.5188C103.422 49.5188 104.314 48.8077 104.314 47.3725C104.314 45.9373 103.422 45.2262 101.637 45.2262H98.5861V49.5188H101.637ZM101.793 54.6647C102.724 54.6647 103.422 54.4708 103.9 54.0958C104.379 53.7209 104.611 53.1778 104.611 52.4667C104.611 51.7427 104.379 51.1996 103.9 50.8247C103.422 50.4497 102.724 50.2687 101.793 50.2687H98.5861V54.6647H101.793Z"
          fill="white"
        />
        <path
          d="M116.662 54.6647V55.4275H109.253V44.4634H110.08V54.6647H116.662Z"
          fill="white"
        />
        <path
          d="M120.579 55.4275H119.752V44.4634H120.579V55.4275Z"
          fill="white"
        />
        <path
          d="M132.086 54.8332C131.31 55.3504 130.392 55.6089 129.319 55.6089C128.324 55.6089 127.444 55.3762 126.695 54.9237C125.945 54.4582 125.363 53.8118 124.949 52.9584C124.535 52.1051 124.328 51.1095 124.328 49.9717C124.328 48.8339 124.535 47.8384 124.949 46.985C125.363 46.1317 125.945 45.4723 126.695 45.0197C127.444 44.5543 128.324 44.3345 129.306 44.3345C131.504 44.3345 132.914 45.2395 133.56 47.0367L132.746 47.4246C132.487 46.6488 132.086 46.067 131.53 45.6791C130.974 45.2913 130.25 45.0973 129.358 45.0973C128.543 45.0973 127.819 45.2913 127.186 45.6921C126.565 46.0799 126.074 46.6488 125.738 47.3858C125.389 48.1228 125.221 48.9891 125.221 49.9846C125.221 50.9802 125.389 51.8465 125.725 52.5835C126.061 53.3204 126.539 53.8893 127.16 54.2772C127.781 54.6651 128.492 54.872 129.332 54.872C130.224 54.872 130.974 54.6522 131.595 54.2255C132.215 53.7988 132.642 53.1782 132.888 52.3766L133.715 52.6869C133.392 53.592 132.862 54.316 132.086 54.8332Z"
          fill="white"
        />
        <path
          d="M150.614 55.4275L147.472 50.6695H147.421H144.175V55.4275H143.348V44.4634H147.421C148.52 44.4634 149.386 44.7349 150.032 45.2909C150.679 45.8468 151.002 46.6097 151.002 47.5664C151.002 48.3681 150.769 49.0275 150.304 49.5576C149.838 50.0877 149.205 50.4239 148.39 50.579L151.584 55.4275H150.614ZM144.175 49.8937H147.395C148.287 49.8937 148.959 49.6998 149.425 49.299C149.89 48.8982 150.136 48.3293 150.136 47.5664C150.136 46.8165 149.903 46.2347 149.425 45.8339C148.959 45.4331 148.274 45.2262 147.395 45.2262H144.175V49.8937Z"
          fill="white"
        />
        <path
          d="M162.742 54.6647V55.4275H154.984V44.4634H162.484V45.2262H155.825V49.4671H161.087V50.2428H155.825V54.6647H162.742Z"
          fill="white"
        />
        <path
          d="M173.551 54.6647V55.4275H166.142V44.4634H166.97V54.6647H173.551Z"
          fill="white"
        />
        <path
          d="M182.731 51.9883H177.482L176.124 55.4275H175.245L179.628 44.4634H180.61L184.994 55.4275H184.114L182.731 51.9883ZM182.434 51.2255L180.52 46.4416L180.119 45.2779H180.106L179.705 46.4028L177.792 51.2255H182.434Z"
          fill="white"
        />
        <path
          d="M193.928 45.2262H189.92V55.4275H189.092V45.2262H185.084V44.4634H193.928V45.2262Z"
          fill="white"
        />
        <path
          d="M197.846 55.4275H197.018V44.4634H197.846V55.4275Z"
          fill="white"
        />
        <path
          d="M209.34 44.9939C210.103 45.4593 210.684 46.1058 211.098 46.9592C211.512 47.8125 211.719 48.8081 211.719 49.9588C211.719 51.1095 211.512 52.1051 211.098 52.9584C210.684 53.8118 210.103 54.4582 209.34 54.9237C208.577 55.3892 207.685 55.609 206.651 55.609C205.616 55.609 204.724 55.3762 203.961 54.9237C203.198 54.4582 202.617 53.8118 202.203 52.9584C201.789 52.1051 201.582 51.1095 201.582 49.9588C201.582 48.8081 201.789 47.8125 202.203 46.9592C202.617 46.1058 203.198 45.4593 203.961 44.9939C204.724 44.5284 205.616 44.3086 206.651 44.3086C207.685 44.2957 208.577 44.5284 209.34 44.9939ZM204.44 45.6533C203.819 46.0412 203.328 46.6101 202.991 47.347C202.655 48.084 202.487 48.9503 202.487 49.9459C202.487 50.9414 202.655 51.8077 202.991 52.5447C203.328 53.2817 203.806 53.8505 204.44 54.2384C205.06 54.6263 205.81 54.8332 206.663 54.8332C207.517 54.8332 208.254 54.6392 208.887 54.2384C209.521 53.8505 209.999 53.2817 210.335 52.5447C210.672 51.8077 210.84 50.9414 210.84 49.9459C210.84 48.9503 210.672 48.084 210.335 47.347C209.999 46.6101 209.508 46.0412 208.887 45.6533C208.254 45.2654 207.517 45.0585 206.663 45.0585C205.81 45.0585 205.06 45.2654 204.44 45.6533Z"
          fill="white"
        />
        <path
          d="M223.885 55.4278H222.851L217.033 46.8168L216.218 45.3557H216.205L216.257 46.7392V55.4278H215.455V44.4636H216.503L222.308 53.0488L223.136 54.5227L223.097 53.1393V44.4507H223.873V55.4278H223.885Z"
          fill="white"
        />
        <path
          d="M235.871 46.7266L235.16 47.3084C234.888 46.5197 234.474 45.9508 233.918 45.6017C233.375 45.2526 232.651 45.0716 231.746 45.0716C231.1 45.0716 230.544 45.175 230.066 45.369C229.587 45.5629 229.225 45.8344 228.979 46.1835C228.734 46.5326 228.604 46.9205 228.604 47.3472C228.604 47.7738 228.708 48.1229 228.915 48.3945C229.122 48.666 229.484 48.9116 229.988 49.1056C230.492 49.2995 231.19 49.4676 232.07 49.6227C233.505 49.8684 234.513 50.2175 235.108 50.683C235.703 51.1484 236 51.7949 236 52.6095C236 53.1913 235.832 53.7214 235.483 54.1739C235.134 54.6264 234.655 54.9755 234.035 55.2341C233.414 55.4798 232.69 55.6091 231.889 55.6091C230.738 55.6091 229.768 55.3764 228.967 54.9238C228.178 54.4584 227.635 53.8248 227.35 52.9844L228.087 52.4155C228.307 53.2042 228.76 53.799 229.419 54.2127C230.091 54.6264 230.919 54.8333 231.902 54.8333C232.858 54.8333 233.634 54.6394 234.242 54.2515C234.849 53.8636 235.147 53.3335 235.147 52.6612C235.147 52.0793 234.901 51.6139 234.41 51.2648C233.918 50.9157 233.065 50.6442 231.837 50.4373C230.802 50.2563 229.988 50.0494 229.393 49.7908C228.798 49.5322 228.372 49.2219 228.1 48.847C227.842 48.472 227.712 47.9936 227.712 47.4377C227.712 46.8688 227.88 46.3516 228.204 45.8732C228.527 45.3948 228.992 45.0199 229.6 44.7354C230.208 44.451 230.906 44.3087 231.72 44.3087C233.789 44.2958 235.173 45.1104 235.871 46.7266Z"
          fill="white"
        />
        <path
          d="M74.201 37.1327V16.4585H82.6828C86.2384 16.4585 88.9148 17.3248 90.7378 19.0444C92.5479 20.7769 93.4659 23.3111 93.4659 26.6469C93.4401 30.0473 92.5221 32.6462 90.6861 34.4304C88.8501 36.2276 86.1866 37.1198 82.6957 37.1198H74.201V37.1327ZM82.9284 31.6247C84.2472 31.6247 85.2169 31.2239 85.8505 30.4094C86.484 29.5948 86.8073 28.3665 86.8073 26.7374C86.8073 25.1342 86.4969 23.9446 85.8634 23.156C85.2299 22.3802 84.2602 21.9794 82.9284 21.9535H80.6399V31.6247H82.9284Z"
          fill="#CF1B1B"
        />
        <path
          d="M103.564 37.1324V29.4523L96.7889 16.4453H103.771L106.926 23.9703L110.106 16.4453H116.946L110.171 29.4523V37.1324H103.564Z"
          fill="#CF1B1B"
        />
        <path
          d="M135.823 37.1327L129.164 26.621V37.1327H123.023V16.4585H128.673L135.28 26.9184V16.4585H141.421V37.1327H135.823Z"
          fill="#CF1B1B"
        />
        <path
          d="M162.639 37.1327L161.708 33.7969H155.165L154.273 37.1327H147.783L154.506 16.4585H162.613L169.271 37.1327H162.639ZM158.449 21.8113L156.562 28.6768H160.324L158.449 21.8113Z"
          fill="#CF1B1B"
        />
        <path
          d="M192.299 37.1327V26.8796L189.234 37.1327H184.606L181.541 26.8796V37.1327H175.659V16.4585H183.184L187.011 29.0259L190.838 16.4585H198.337V37.1327H192.299Z"
          fill="#CF1B1B"
        />
        <path
          d="M205.849 37.1327V16.4585H212.417V37.1327H205.849Z"
          fill="#CF1B1B"
        />
        <path
          d="M239 29.2199C238.871 31.8834 237.966 33.9521 236.298 35.4261C234.63 36.9 232.354 37.637 229.484 37.637C226.239 37.637 223.743 36.719 221.998 34.883C220.252 33.047 219.373 30.4224 219.373 27.009C219.373 23.4793 220.278 20.7382 222.075 18.8117C223.885 16.8852 226.42 15.9155 229.704 15.9155C232.328 15.9155 234.384 16.5361 235.858 17.7644C237.332 19.0057 238.276 20.8675 238.677 23.3758L232.134 24.0999C231.94 23.2077 231.63 22.5613 231.203 22.1605C230.777 21.7596 230.195 21.5528 229.432 21.5528C228.372 21.5528 227.583 21.9665 227.066 22.8069C226.549 23.6473 226.303 24.9403 226.303 26.6987C226.303 28.5088 226.575 29.8405 227.118 30.6939C227.661 31.5472 228.501 31.9739 229.639 31.9739C231.281 31.9739 232.212 31.043 232.445 29.1941H239V29.2199Z"
          fill="#CF1B1B"
        />
        <path
          d="M47.5406 60.3925L44.9935 55.7121C51.2902 52.2858 55.8672 46.6227 57.8971 39.7442C59.9271 32.8787 59.1513 25.6252 55.725 19.3286C52.2987 13.032 46.6356 8.45493 39.7571 6.42501C32.8916 4.39509 25.6382 5.17085 19.3415 8.59716C13.0449 12.0235 8.46786 17.6866 6.43794 24.565C4.40802 31.4306 5.18378 38.684 8.61009 44.9806C11.0538 49.4671 14.6869 53.1391 19.1347 55.5957L16.5488 60.2632C11.2218 57.3024 6.85168 52.9064 3.92963 47.5277C-0.181936 39.9898 -1.09993 31.3013 1.33081 23.0652C3.76154 14.8292 9.25656 8.02826 16.7944 3.92963C24.3323 -0.181936 33.0209 -1.09993 41.2569 1.33081C49.493 3.76154 56.2939 9.25656 60.3925 16.7944C64.5041 24.3323 65.4221 33.0209 62.9913 41.2569C60.5606 49.493 55.0785 56.2809 47.5406 60.3925Z"
          fill="#CF1B1B"
        />
        <path
          d="M32.1934 55.4667C29.698 55.4667 27.2026 55.053 24.7848 54.2384C18.9665 52.2602 14.2602 48.1357 11.545 42.6278C8.82985 37.1198 8.40317 30.8749 10.3684 25.0566C12.3467 19.2384 16.4711 14.5321 21.9791 11.8169L24.3323 16.6008C20.0914 18.6954 16.9237 22.3027 15.4109 26.7763C13.8982 31.2499 14.2085 36.0467 16.3031 40.2746C19.3803 46.5066 25.664 50.1268 32.1934 50.1268C34.818 50.1268 37.4815 49.545 39.9898 48.3038C44.2307 46.2092 47.3984 42.6019 48.9111 38.1283C50.4239 33.6547 50.1136 28.8579 48.019 24.63C46.5321 21.6045 44.2307 19.0962 41.3733 17.3507L44.1402 12.7995C47.8638 15.0622 50.8635 18.3463 52.8029 22.2768C55.5181 27.7848 55.9318 34.0297 53.9536 39.8479C51.9754 45.6662 47.8509 50.3725 42.343 53.0877C39.1365 54.6651 35.6714 55.4667 32.1934 55.4667Z"
          fill="white"
        />
        <path
          d="M32.1675 17.4412C34.5025 17.4412 36.3954 15.5483 36.3954 13.2133C36.3954 10.8783 34.5025 8.98535 32.1675 8.98535C29.8325 8.98535 27.9396 10.8783 27.9396 13.2133C27.9396 15.5483 29.8325 17.4412 32.1675 17.4412Z"
          fill="white"
        />
        <path
          d="M32.1675 65.306C34.5025 65.306 36.3954 63.413 36.3954 61.078C36.3954 58.743 34.5025 56.8501 32.1675 56.8501C29.8325 56.8501 27.9396 58.743 27.9396 61.078C27.9396 63.413 29.8325 65.306 32.1675 65.306Z"
          fill="#CF1B1B"
        />
        <path
          d="M32.814 45.6272H24.022V19.8071H32.814C39.9381 19.8071 45.7305 25.5995 45.7305 32.7236C45.7305 39.8478 39.9252 45.6272 32.814 45.6272ZM29.3489 40.3003H32.8011C36.9773 40.3003 40.3777 36.8999 40.3777 32.7236C40.3777 28.5474 36.9773 25.147 32.8011 25.147H29.3489V40.3003Z"
          fill="#CF1B1B"
        />
      </svg>
    </>
  );
};
export const Facebook = () => {
  return (
    <>
      <svg
        className="transition-all ease-linear duration-300 hover:scale-[1.4] footer_svg"
        width="30"
        height="31"
        viewBox="0 0 30 31"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.306152" width="30" height="30" rx="15" fill="white" />
        <path
          d="M12.6646 23.223H15.998V16.548H19.0013L19.3313 13.2313H15.998V11.5563C15.998 11.3353 16.0858 11.1233 16.242 10.9671C16.3983 10.8108 16.6103 10.723 16.8313 10.723H19.3313V7.38965H16.8313C15.7262 7.38965 14.6664 7.82864 13.885 8.61004C13.1036 9.39144 12.6646 10.4512 12.6646 11.5563V13.2313H10.998L10.668 16.548H12.6646V23.223Z"
          fill="#CF1B1B"
        />
      </svg>
    </>
  );
};
export const Twitter = () => {
  return (
    <>
      <svg
        className="transition-all ease-linear duration-300 hover:scale-[1.4] footer_svg"
        width="30"
        height="31"
        viewBox="0 0 30 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.306152" width="30" height="30" rx="15" fill="white" />
        <path
          d="M23.4375 10.1558C22.8045 10.4308 22.1347 10.612 21.4494 10.6937C22.1696 10.2719 22.7113 9.6019 22.9727 8.80928C22.2918 9.20746 21.548 9.48684 20.7734 9.63546C20.4471 9.29357 20.0548 9.02159 19.6202 8.83605C19.1856 8.65051 18.7178 8.55528 18.2453 8.55616C16.3321 8.55616 14.7838 10.0819 14.7838 11.9628C14.7824 12.2244 14.8124 12.4853 14.8731 12.7398C13.5012 12.6754 12.1578 12.3255 10.9289 11.7122C9.7 11.099 8.61263 10.236 7.73637 9.17842C7.42895 9.69667 7.26637 10.288 7.26562 10.8905C7.26562 12.0718 7.88191 13.1159 8.8125 13.7276C8.26115 13.7146 7.72111 13.5686 7.2382 13.3023V13.3444C7.2382 14.9968 8.43352 16.3714 10.0155 16.6843C9.71805 16.7636 9.41148 16.8038 9.10359 16.8038C8.88513 16.8042 8.66715 16.783 8.45285 16.7405C8.89266 18.094 10.1727 19.0784 11.689 19.1065C10.4569 20.0561 8.9442 20.5693 7.38867 20.5655C7.11256 20.5651 6.83671 20.5487 6.5625 20.5163C8.14495 21.5268 9.98441 22.0613 11.862 22.0562C18.2379 22.0562 21.7212 16.8636 21.7212 12.3601C21.7212 12.2124 21.7173 12.0648 21.7103 11.9206C22.3864 11.4397 22.9713 10.8421 23.4375 10.1558Z"
          fill="#CF1B1B"
        />
      </svg>
    </>
  );
};
export const Insta = () => {
  return (
    <>
      <svg
        className="transition-all ease-linear duration-300 hover:scale-[1.4] footer_svg"
        width="30"
        height="31"
        viewBox="0 0 30 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.306152" width="30" height="30" rx="15" fill="white" />
        <path
          d="M14.9997 14.1396C14.425 14.1396 13.8739 14.3679 13.4676 14.7743C13.0613 15.1806 12.833 15.7317 12.833 16.3063C12.833 16.881 13.0613 17.4321 13.4676 17.8384C13.8739 18.2447 14.425 18.473 14.9997 18.473C15.5743 18.473 16.1254 18.2447 16.5317 17.8384C16.9381 17.4321 17.1663 16.881 17.1663 16.3063C17.1663 15.7317 16.9381 15.1806 16.5317 14.7743C16.1254 14.3679 15.5743 14.1396 14.9997 14.1396Z"
          fill="#CF1B1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5133 10.3606C13.8306 10.1038 16.1693 10.1038 18.4866 10.3606C19.7526 10.502 20.7733 11.4986 20.922 12.7693C21.1968 15.1191 21.1968 17.4928 20.922 19.8426C20.7733 21.1133 19.7526 22.11 18.4873 22.252C16.1697 22.5089 13.8309 22.5089 11.5133 22.252C10.2473 22.11 9.22663 21.1133 9.07796 19.8433C8.80311 17.4933 8.80311 15.1193 9.07796 12.7693C9.22663 11.4986 10.2473 10.502 11.5133 10.3606ZM18.3333 12.306C18.1565 12.306 17.9869 12.3762 17.8619 12.5012C17.7369 12.6262 17.6666 12.7958 17.6666 12.9726C17.6666 13.1494 17.7369 13.319 17.8619 13.444C17.9869 13.569 18.1565 13.6393 18.3333 13.6393C18.5101 13.6393 18.6797 13.569 18.8047 13.444C18.9297 13.319 19 13.1494 19 12.9726C19 12.7958 18.9297 12.6262 18.8047 12.5012C18.6797 12.3762 18.5101 12.306 18.3333 12.306ZM11.8333 16.306C11.8333 15.4661 12.1669 14.6606 12.7608 14.0668C13.3547 13.4729 14.1601 13.1393 15 13.1393C15.8398 13.1393 16.6453 13.4729 17.2391 14.0668C17.833 14.6606 18.1666 15.4661 18.1666 16.306C18.1666 17.1458 17.833 17.9513 17.2391 18.5451C16.6453 19.139 15.8398 19.4726 15 19.4726C14.1601 19.4726 13.3547 19.139 12.7608 18.5451C12.1669 17.9513 11.8333 17.1458 11.8333 16.306Z"
          fill="#CF1B1B"
        />
      </svg>
    </>
  );
};
export const Youtube = () => {
  return (
    <>
      <svg
        className="transition-all ease-linear duration-300 hover:scale-[1.4] footer_svg"
        width="30"
        height="31"
        viewBox="0 0 30 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.306152" width="30" height="30" rx="15" fill="white" />
        <path
          d="M22.5435 11.5147C22.4539 11.1817 22.2784 10.8781 22.0346 10.6343C21.7907 10.3905 21.4872 10.215 21.1542 10.1253C19.9279 9.79346 15.0004 9.79346 15.0004 9.79346C15.0004 9.79346 10.0729 9.79346 8.84667 10.1253C8.5137 10.215 8.2101 10.3905 7.96627 10.6343C7.72244 10.8781 7.54693 11.1817 7.4573 11.5147C7.22833 12.7653 7.11722 14.0346 7.12542 15.306C7.11722 16.5773 7.22833 17.8466 7.4573 19.0972C7.54693 19.4302 7.72244 19.7338 7.96627 19.9776C8.2101 20.2214 8.5137 20.397 8.84667 20.4866C10.0729 20.8185 15.0004 20.8185 15.0004 20.8185C15.0004 20.8185 19.9279 20.8185 21.1542 20.4866C21.4872 20.397 21.7907 20.2214 22.0346 19.9776C22.2784 19.7338 22.4539 19.4302 22.5435 19.0972C22.7725 17.8466 22.8836 16.5773 22.8754 15.306C22.8836 14.0346 22.7725 12.7653 22.5435 11.5147ZM13.4254 17.6685V12.9435L17.5148 15.306L13.4254 17.6685Z"
          fill="#CF1B1B"
        />
      </svg>
    </>
  );
};
export const Telegram = () => {
  return (
    <>
      <svg
        className="transition-all ease-linear duration-300 hover:scale-[1.4] footer_svg"
        width="30"
        height="31"
        viewBox="0 0 30 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.306152" width="30" height="30" rx="15" fill="white" />
        <path
          d="M21.4985 9.09401L8.20095 14.2218C7.29345 14.5863 7.2987 15.0925 8.03445 15.3183L11.4485 16.3833L19.3475 11.3995C19.721 11.1723 20.0622 11.2945 19.7817 11.5435L13.382 17.3193H13.3805L13.382 17.32L13.1465 20.839C13.4915 20.839 13.6437 20.6808 13.8372 20.494L15.4955 18.8815L18.9447 21.4293C19.5807 21.7795 20.0375 21.5995 20.1957 20.8405L22.46 10.1695C22.6917 9.24026 22.1052 8.81951 21.4985 9.09401Z"
          fill="#CF1B1B"
        />
      </svg>
    </>
  );
};
export const StringsIcon = () => {
  return (
    <>
      <svg
        width="86"
        height="58"
        viewBox="0 0 86 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.7">
          <path
            d="M28.1486 57.576L17.8583 36.9954H38.4389V0.979248H2.42278V36.9954L12.7131 57.576H28.1486ZM74.455 57.576L64.1647 36.9954H84.7454V0.979248H48.7292V36.9954L59.0196 57.576H74.455Z"
            fill="#1599BC"
          />
        </g>
      </svg>
    </>
  );
};
export const FooterLogo = () => {
  return (
    <>
      <svg
        className="w-full h-full"
        width="182"
        height="50"
        viewBox="0 0 182 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M61.4571 34.509C61.9691 34.9422 62.2251 35.5428 62.2251 36.301C62.2251 37.0591 61.9691 37.6597 61.4571 38.1028C60.9451 38.5458 60.2559 38.7624 59.3895 38.7624H57.2529V42.2183H56.6228V33.869H59.3895C60.2559 33.8592 60.9451 34.0758 61.4571 34.509ZM60.9747 37.6794C61.3587 37.3545 61.5457 36.9016 61.5457 36.301C61.5457 35.7102 61.3587 35.2573 60.9747 34.9324C60.5907 34.6075 60.0393 34.4499 59.3107 34.4499H57.2529V38.1717H59.3107C60.0393 38.1618 60.5907 38.0043 60.9747 37.6794Z"
          fill="#ffffff"
        />
        <path
          d="M71.1455 39.1661C71.1455 40.1999 70.8698 40.9777 70.3086 41.5291C69.7474 42.0706 68.9597 42.3463 67.9259 42.3463C66.8921 42.3463 66.1044 42.0706 65.5432 41.5291C64.982 40.9876 64.7063 40.1999 64.7063 39.1661V33.8592H65.3364V39.0381C65.3364 39.9341 65.553 40.6134 65.9961 41.0664C66.4392 41.5291 67.0791 41.7556 67.9357 41.7556C68.7825 41.7556 69.4225 41.5291 69.8557 41.0664C70.2987 40.6036 70.5154 39.9341 70.5154 39.0381V33.8592H71.1455V39.1661Z"
          fill="#ffffff"
        />
        <path
          d="M74.4341 33.8592H77.3878C78.3035 33.8592 78.9829 34.0463 79.4358 34.4302C79.8887 34.8142 80.1151 35.3558 80.1151 36.0745C80.1151 36.5668 79.9871 36.9803 79.7213 37.3151C79.4555 37.6498 79.0813 37.8763 78.589 37.9748V37.9846C79.1306 38.0732 79.5539 38.2898 79.869 38.6443C80.1841 38.9987 80.3416 39.4418 80.3416 39.9636C80.3416 40.6824 80.0856 41.2337 79.5736 41.6276C79.0616 42.0214 78.333 42.2183 77.3878 42.2183H74.4341V33.8592ZM77.3977 37.7089C78.7564 37.7089 79.4358 37.1674 79.4358 36.0745C79.4358 34.9816 78.7564 34.4401 77.3977 34.4401H75.0741V37.7089H77.3977ZM77.5158 41.6276C78.2247 41.6276 78.7564 41.4799 79.1207 41.1943C79.485 40.9088 79.6622 40.4953 79.6622 39.9538C79.6622 39.4024 79.485 38.9889 79.1207 38.7033C78.7564 38.4178 78.2247 38.28 77.5158 38.28H75.0741V41.6276H77.5158Z"
          fill="#ffffff"
        />
        <path
          d="M88.8385 41.6276V42.2085H83.1968V33.8592H83.8269V41.6276H88.8385Z"
          fill="#ffffff"
        />
        <path
          d="M91.8218 42.2085H91.1917V33.8592H91.8218V42.2085Z"
          fill="#ffffff"
        />
        <path
          d="M100.585 41.7556C99.9938 42.1494 99.2947 42.3463 98.4775 42.3463C97.7194 42.3463 97.0499 42.1691 96.4788 41.8245C95.9077 41.47 95.4647 40.9777 95.1496 40.3279C94.8345 39.6781 94.677 38.92 94.677 38.0535C94.677 37.1871 94.8345 36.429 95.1496 35.7791C95.4647 35.1293 95.9077 34.6272 96.4788 34.2826C97.0499 33.9281 97.7194 33.7607 98.4677 33.7607C100.141 33.7607 101.215 34.4499 101.707 35.8185L101.087 36.1139C100.89 35.5231 100.585 35.0801 100.161 34.7847C99.7378 34.4893 99.1864 34.3416 98.507 34.3416C97.8868 34.3416 97.3354 34.4893 96.8529 34.7945C96.3803 35.0899 96.0062 35.5231 95.7502 36.0844C95.4844 36.6456 95.3564 37.3052 95.3564 38.0634C95.3564 38.8215 95.4844 39.4812 95.7404 40.0424C95.9964 40.6036 96.3606 41.0368 96.8332 41.3322C97.3058 41.6276 97.8474 41.7851 98.4874 41.7851C99.1667 41.7851 99.7378 41.6177 100.21 41.2928C100.683 40.9679 101.008 40.4953 101.195 39.8849L101.825 40.1212C101.579 40.8104 101.175 41.3617 100.585 41.7556Z"
          fill="#ffffff"
        />
        <path
          d="M114.694 42.2085L112.301 38.5852H112.262H109.791V42.2085H109.16V33.8592H112.262C113.099 33.8592 113.758 34.0659 114.251 34.4893C114.743 34.9127 114.989 35.4936 114.989 36.2222C114.989 36.8326 114.812 37.3348 114.457 37.7385C114.103 38.1421 113.621 38.3981 113 38.5163L115.432 42.2085H114.694ZM109.791 37.9944H112.242C112.922 37.9944 113.434 37.8468 113.788 37.5415C114.142 37.2363 114.329 36.8031 114.329 36.2222C114.329 35.6511 114.152 35.2081 113.788 34.9028C113.434 34.5976 112.912 34.4401 112.242 34.4401H109.791V37.9944Z"
          fill="#ffffff"
        />
        <path
          d="M123.929 41.6276V42.2085H118.022V33.8592H123.732V34.4401H118.662V37.6695H122.669V38.2603H118.662V41.6276H123.929Z"
          fill="#ffffff"
        />
        <path
          d="M132.16 41.6276V42.2085H126.519V33.8592H127.149V41.6276H132.16Z"
          fill="#ffffff"
        />
        <path
          d="M139.151 39.5895H135.153L134.12 42.2085H133.45L136.788 33.8592H137.536L140.874 42.2085H140.204L139.151 39.5895ZM138.924 39.0086L137.467 35.3656L137.162 34.4795H137.152L136.847 35.3361L135.39 39.0086H138.924Z"
          fill="#ffffff"
        />
        <path
          d="M147.677 34.4401H144.625V42.2085H143.995V34.4401H140.943V33.8592H147.677V34.4401Z"
          fill="#ffffff"
        />
        <path
          d="M150.661 42.2085H150.031V33.8592H150.661V42.2085Z"
          fill="#ffffff"
        />
        <path
          d="M159.414 34.2629C159.995 34.6173 160.438 35.1096 160.753 35.7594C161.068 36.4093 161.225 37.1674 161.225 38.0437C161.225 38.92 161.068 39.6781 160.753 40.3279C160.438 40.9777 159.995 41.47 159.414 41.8245C158.833 42.1789 158.153 42.3463 157.366 42.3463C156.578 42.3463 155.899 42.1691 155.318 41.8245C154.737 41.47 154.294 40.9777 153.979 40.3279C153.664 39.6781 153.506 38.92 153.506 38.0437C153.506 37.1674 153.664 36.4093 153.979 35.7594C154.294 35.1096 154.737 34.6173 155.318 34.2629C155.899 33.9084 156.578 33.741 157.366 33.741C158.153 33.7312 158.833 33.9084 159.414 34.2629ZM155.682 34.765C155.209 35.0604 154.835 35.4936 154.579 36.0548C154.323 36.616 154.195 37.2757 154.195 38.0338C154.195 38.792 154.323 39.4516 154.579 40.0129C154.835 40.5741 155.2 41.0073 155.682 41.3027C156.155 41.598 156.726 41.7556 157.376 41.7556C158.025 41.7556 158.587 41.6079 159.069 41.3027C159.551 41.0073 159.916 40.5741 160.172 40.0129C160.428 39.4516 160.556 38.792 160.556 38.0338C160.556 37.2757 160.428 36.616 160.172 36.0548C159.916 35.4936 159.542 35.0604 159.069 34.765C158.587 34.4696 158.025 34.3121 157.376 34.3121C156.726 34.3121 156.155 34.4696 155.682 34.765Z"
          fill="#ffffff"
        />
        <path
          d="M170.49 42.2085H169.703L165.272 35.6511L164.652 34.5386H164.642L164.681 35.5921V42.2085H164.071V33.8592H164.868L169.289 40.3968L169.919 41.5193L169.89 40.4658V33.8493H170.48V42.2085H170.49Z"
          fill="#ffffff"
        />
        <path
          d="M179.617 35.5822L179.076 36.0253C178.869 35.4247 178.554 34.9915 178.131 34.7256C177.717 34.4598 177.166 34.322 176.476 34.322C175.984 34.322 175.561 34.4007 175.197 34.5484C174.832 34.6961 174.557 34.9029 174.369 35.1687C174.182 35.4345 174.084 35.7299 174.084 36.0548C174.084 36.3797 174.163 36.6456 174.32 36.8523C174.478 37.0591 174.753 37.2462 175.137 37.3939C175.521 37.5416 176.053 37.6696 176.723 37.7877C177.816 37.9748 178.584 38.2406 179.036 38.5951C179.489 38.9495 179.716 39.4418 179.716 40.0621C179.716 40.5052 179.588 40.9088 179.322 41.2534C179.056 41.598 178.692 41.8639 178.219 42.0608C177.747 42.2479 177.195 42.3463 176.585 42.3463C175.709 42.3463 174.97 42.1691 174.36 41.8245C173.759 41.4701 173.346 40.9876 173.129 40.3476L173.69 39.9144C173.857 40.515 174.202 40.9679 174.704 41.283C175.216 41.598 175.846 41.7556 176.595 41.7556C177.323 41.7556 177.914 41.6079 178.377 41.3125C178.839 41.0171 179.066 40.6135 179.066 40.1015C179.066 39.6584 178.879 39.304 178.505 39.0381C178.131 38.7723 177.481 38.5655 176.545 38.408C175.758 38.2701 175.137 38.1126 174.685 37.9157C174.232 37.7188 173.907 37.4825 173.7 37.1969C173.503 36.9114 173.405 36.5471 173.405 36.1238C173.405 35.6905 173.533 35.2967 173.779 34.9324C174.025 34.5681 174.379 34.2826 174.842 34.066C175.305 33.8494 175.837 33.7411 176.457 33.7411C178.032 33.7312 179.086 34.3515 179.617 35.5822Z"
          fill="#ffffff"
        />
        <path
          d="M56.5046 28.2766V12.5331H62.9635C65.6711 12.5331 67.7092 13.1927 69.0975 14.5022C70.4759 15.8216 71.175 17.7514 71.175 20.2916C71.1553 22.8811 70.4562 24.8601 69.0581 26.2188C67.66 27.5874 65.6317 28.2668 62.9734 28.2668H56.5046V28.2766ZM63.1506 24.0823C64.1549 24.0823 64.8933 23.777 65.3758 23.1568C65.8582 22.5365 66.1044 21.6011 66.1044 20.3605C66.1044 19.1396 65.868 18.2338 65.3856 17.6332C64.9032 17.0425 64.1647 16.7373 63.1506 16.7176H61.4079V24.0823H63.1506Z"
          fill="#CF1B1B"
        />
        <path
          d="M78.8647 28.2766V22.4282L73.7054 12.5232H79.0222L81.4246 18.2535L83.8467 12.5232H89.0551L83.8959 22.4282V28.2766H78.8647Z"
          fill="#CF1B1B"
        />
        <path
          d="M103.43 28.2766L98.3595 20.2719V28.2766H93.6827V12.5331H97.9854L103.017 20.4984V12.5331H107.693V28.2766H103.43Z"
          fill="#CF1B1B"
        />
        <path
          d="M123.85 28.2766L123.141 25.7364H118.159L117.48 28.2766H112.537L117.657 12.5331H123.831L128.901 28.2766H123.85ZM120.66 16.6093L119.223 21.8374H122.088L120.66 16.6093Z"
          fill="#CF1B1B"
        />
        <path
          d="M146.437 28.2766V20.4688L144.103 28.2766H140.578L138.245 20.4688V28.2766H133.765V12.5331H139.495L142.41 22.1033L145.324 12.5331H151.035V28.2766H146.437Z"
          fill="#CF1B1B"
        />
        <path
          d="M156.755 28.2766V12.5331H161.757V28.2766H156.755Z"
          fill="#CF1B1B"
        />
        <path
          d="M182 22.2509C181.902 24.2792 181.212 25.8545 179.942 26.9769C178.672 28.0994 176.939 28.6606 174.753 28.6606C172.282 28.6606 170.382 27.9615 169.053 26.5634C167.723 25.1653 167.054 23.1666 167.054 20.5673C167.054 17.8794 167.743 15.792 169.112 14.325C170.49 12.858 172.42 12.1195 174.921 12.1195C176.92 12.1195 178.485 12.5921 179.607 13.5275C180.73 14.4727 181.449 15.8905 181.754 17.8006L176.772 18.352C176.624 17.6726 176.388 17.1803 176.063 16.8751C175.738 16.5699 175.295 16.4123 174.714 16.4123C173.907 16.4123 173.306 16.7274 172.912 17.3674C172.518 18.0074 172.331 18.9919 172.331 20.331C172.331 21.7094 172.538 22.7235 172.952 23.3734C173.365 24.0232 174.005 24.3481 174.872 24.3481C176.122 24.3481 176.831 23.6392 177.008 22.2312H182V22.2509Z"
          fill="#CF1B1B"
        />
        <path
          d="M36.2025 45.9893L34.2629 42.4251C39.0578 39.816 42.5433 35.5035 44.0891 30.2655C45.6348 25.0373 45.0441 19.5138 42.4349 14.7189C39.8258 9.92392 35.5133 6.43849 30.2753 4.89269C25.0472 3.34689 19.5236 3.93764 14.7287 6.54679C9.93377 9.15595 6.44833 13.4684 4.90253 18.7064C3.35673 23.9346 3.94749 29.4581 6.55664 34.253C8.41751 37.6696 11.1842 40.4658 14.5712 42.3365L12.602 45.8908C8.5455 43.6361 5.2176 40.2886 2.99244 36.1927C-0.138546 30.4525 -0.837602 23.8361 1.01342 17.5643C2.86444 11.2925 7.04893 6.11357 12.7891 2.99244C18.5292 -0.138546 25.1456 -0.837602 31.4174 1.01342C37.6892 2.86444 42.8682 7.04893 45.9893 12.7891C49.1203 18.5292 49.8193 25.1456 47.9683 31.4174C46.1173 37.6892 41.9427 42.8583 36.2025 45.9893Z"
          fill="#CF1B1B"
        />
        <path
          d="M24.5155 42.238C22.6152 42.238 20.715 41.923 18.8738 41.3027C14.4431 39.7963 10.8592 36.6554 8.79162 32.4611C6.72398 28.2668 6.39907 23.5112 7.89564 19.0806C9.40206 14.6499 12.5429 11.066 16.7372 8.99841L18.5292 12.6414C15.2997 14.2364 12.8875 16.9834 11.7355 20.3901C10.5836 23.7967 10.8199 27.4495 12.4149 30.6691C14.7582 35.4149 19.5433 38.1717 24.5155 38.1717C26.5142 38.1717 28.5424 37.7286 30.4525 36.7834C33.6819 35.1884 36.0942 32.4414 37.2461 29.0347C38.3981 25.6281 38.1618 21.9753 36.5668 18.7557C35.4345 16.4517 33.6819 14.5416 31.506 13.2124L33.613 9.74669C36.4486 11.4697 38.7329 13.9706 40.2098 16.9637C42.2774 21.158 42.5924 25.9136 41.086 30.3442C39.5796 34.7749 36.4388 38.3588 32.2445 40.4264C29.8027 41.6276 27.164 42.238 24.5155 42.238Z"
          fill="#1599BC"
        />
        <path
          d="M24.4957 13.2814C26.2739 13.2814 27.7153 11.8399 27.7153 10.0618C27.7153 8.28363 26.2739 6.84216 24.4957 6.84216C22.7176 6.84216 21.2761 8.28363 21.2761 10.0618C21.2761 11.8399 22.7176 13.2814 24.4957 13.2814Z"
          fill="#1599BC"
        />
        <path
          d="M24.4957 49.7307C26.2739 49.7307 27.7153 48.2893 27.7153 46.5111C27.7153 44.733 26.2739 43.2915 24.4957 43.2915C22.7176 43.2915 21.2761 44.733 21.2761 46.5111C21.2761 48.2893 22.7176 49.7307 24.4957 49.7307Z"
          fill="#CF1B1B"
        />
        <path
          d="M24.988 34.7453H18.2928V15.0831H24.988C30.4131 15.0831 34.824 19.4941 34.824 24.9192C34.824 30.3442 30.4033 34.7453 24.988 34.7453ZM22.3493 30.6888H24.9782C28.1584 30.6888 30.7479 28.0994 30.7479 24.9192C30.7479 21.7389 28.1584 19.1495 24.9782 19.1495H22.3493V30.6888Z"
          fill="#CF1B1B"
        />
      </svg>
    </>
  );
};
