import React from "react";
import { StringsIcon } from "./common/Icons";

const CustomerCard = ({ item, handleOpenModal, index }) => {
  return (
    <>
      <div>
        <div className="flex flex-col sm:flex-row items-start">
          <img
            className="customer_slider_img_01 rounded-[8px] w-full sm:w-[unset] sm:min-w-[308px] lg:min-w-[unset] lg:max-w-[380px] max-h-[400px] sm:min-h-[428px] sm:max-h-[428px] lg:min-h-[588px] lg:max-h-[588px] slider-image-parent object-contain px-[2px] 2xl:min-w-[455px] 2xl:max-h-[610px]"
            src={item.sliderImg}
            alt="overviewSliderImg"
          />
          <div className="px-[3px] sm:px-[16px] md:px-[0px] my-[30px] md:my-[8px] sm:max-w-[527.13px] md:ml-[32px] lg:ml-[22px] xl:ml-[50px] 2xl:ml-[58px]">
            <div className="text_overflow_dots01">
              <div className="string_icon">
                <StringsIcon />
              </div>
              <h4 className="text-base sm:text-sm lg:text-md text-black3a font-medium ff_inter mt-[10px] sm:mt-[30px] lg:mt-[40px] mb-[8px]">
                {item.sliderHeading}
              </h4>
              <p className="text-xs sm:text-base lg:text-sm font-normal text-black3a ff_inter opacity-[.7] leading-[174%]">
                {item.sliderPara}
              </p>
            </div>
            <button
              onClick={(e) => handleOpenModal(item.sliderPara, index)}
              className="inline-block text-[#CF1B1B] text-start ff_inter whitespace-nowrap"
            >
              Read more
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerCard;
