import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { TheNewsslider } from "./common/Helper";
import { NextArow, PrevArow } from "./common/Icons";
import TriangleImg from "../assets/images/png/about-triangle-img.png";
import TriangleImgBorder from "../assets/images/png/about-triangle-border-img.png";
import youtubue_logo from "../assets/images/svg/logos_youtube_icon.svg";

const TheNews = () => {
  const sliderRef = useRef(null);
  const settings = {
    pauseOnHover: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 1023.98,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 639.98,
        settings: {
          slidesToShow: 1.4,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 449.98,
        settings: {
          slidesToShow: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  const [videoplay, setVideoplay] = useState();
  const playVideoHandler = (obj) => {
    console.log("fghj", obj);
    setVideoplay(obj);
  };
  return (
    <>
      <div
        className="bg-[#f9f9f9] pt-[48px] pb-[60px] md:py-[48px] lg:pt-[78px] lg:pb-[62px] relative"
        id="remove_slider_dots"
      >
        <img
          className="hidden sm:block absolute right-[9%] top-[15%] w-[25px] md:w-[46px] rotate md:right-[2%]  lg:top-[6%] z-10 animate-[wiggle_infinite_linear_2s]"
          src={TriangleImg}
          alt="triangle-img"
        />
        <img
          className="hidden sm:block absolute right-[11%] rotate w-[25px] md:w-[46px] top-[15%] md:right-[2.6%] lg:top-[6.5%] "
          src={TriangleImgBorder}
          alt="triangle-img"
        />
        <div
          id="news"
          className="md:max-w-[736px] lg:max-w-[1140px] mx-auto px-5 md:px-10 xl:px-0 relative"
        >
          <h2 className="text-black20 text-[34px] leading-[43px] sm:leading-[56px]  md:text-lg font-medium ff_inter text-center mb-[20 px] sm:mb-[48px] lg:mb-[54px] ">
            Our Clients in <span className="text-red">the News!</span>
          </h2>
          <div className="mx-sm-5 relative">
            <Slider {...settings} ref={sliderRef}>
              {TheNewsslider.map((obj, index) => (
                <div className="" key={index}>
                  <div className="px-2 sm:px-3 py-4" key={index}>
                    <div className="px-[14px] pt-[14px] pb-[22px] bg-white border border-solid rounded-xl transition-all ease-linear duration-300 hover:shadow-[0px_7px_23px_rgba(193,193,193,0.25);] hover:border-transparent">
                      <div className="relative rounded-[8px]">
                        {videoplay === index ? (
                          <video
                            width="100%"
                            controls
                            autoplay
                            muted
                            loop
                            className="h-[175px]"
                          >
                            <source src={obj.video} type="video/mp4" />
                          </video>
                        ) : obj.video === "" ? (
                          <>
                           <img
                              src={obj.pressImg}
                              alt="sliderImg"
                              className="w-full h-[175px] object-cover rounded-[8px]"
                            />
                          </> 
                        ) : (
                          <>
                            <img
                              src={obj.pressImg}
                              alt="sliderImg"
                              className="w-full h-[175px] object-cover rounded-[8px]"
                            />
                            <img
                              src={youtubue_logo}
                              alt=""
                              className="absolute top-[45%] right-[45%] cursor-pointer"
                              onClick={() => playVideoHandler(index)}
                            />
                          </>
                        )}
                      </div>
                      <p className="font-medium text-sm lg:text-md ff_inter text-black3a mb-1 mt-[22px]">
                        {obj.loremBig}
                      </p>
                      <p className="font-normal text-xs lg:text-base ff_inter opacity-80 text-black3a leading-[174%]">
                        {obj.loremPara}
                      </p>

                      <a href= {obj.link}
                       target={"_blank"}
                       rel="noreferrer"
                      >
                      <button className="font-normal leading-[19px] text-base ff_inter text-black3a py-[10px] px-[23px] rounded-[100px] border border-[rgba(0,0,0,0.3)] mt-[25px] focus:shadow-outline  duration-500 hover:bg-[#CF1B1B] hover:text-white">
                        {obj.readBtn}
                      </button>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div>
            <span
              className="cursor-pointer prev_arrow top-[58%] transition-all ease-in-out absolute left-[-1%] lg:left-[1%] xl:left-[-5%] hidden md:block w-[33px] xl:w-[46px]"
              onClick={() => sliderRef.current.slickPrev()}
            >
              <PrevArow />
            </span>
            <span
              className="cursor-pointer ms-3 next_arrow top-[58%] transition-all ease-in-out absolute right-[-1%] lg:right-[1%] xl:right-[-5%] hidden md:block w-[33px] xl:w-[46px]"
              onClick={() => sliderRef.current.slickNext()}
            >
              <NextArow />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default TheNews;
