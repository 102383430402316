import React from "react";
import TriangleImg from "../assets/images/png/about-triangle-img.png";
import TriangleImgBorder from "../assets/images/png/about-triangle-border-img.png";
import SquareImg from "../assets/images/png/about-square-img-border.png";
import SquareImgBorder from "../assets/images/png/about-square-img.png";

import about_animtion from "../assets/lottie/about_us_animation.json";
import Lottie from "react-lottie-player";

const Aboutus = () => {
  return (
    <>
      <div
        className="py-14 md:py-[80px] lg:py-12 2xl:pt-3 mb:sm-12 lg:mb-24 relative"
        id="about"
      >
        <img
          className="absolute right-[9%] top-[10]  lg:right-[2%]  md:top-[-4%] 2xl:top-[-15%] z-10 animate-[wiggle_infinite_linear_2s] hidden lg:block"
          src={TriangleImg}
          alt="triangle-img"
        />
        <img
          className="absolute right-[11%] top-[7%] lg:right-[2.6%] md:top-[-5%] 2xl:top-[-15.5%]  hidden lg:block"
          src={TriangleImgBorder}
          alt="triangle-img"
        />
        <img
          className="hidden absolute left-[53%] md:left-[41%] lg:left-[24.5%] bottom-[6.5%]  lg:bottom-[-10%] circel_animate"
          src={SquareImgBorder}
          alt="triangle-img"
        />
        <img
          className="hidden absolute left-[52.5%] md:left-[40.5%] lg:left-[24.5%] bottom-[6.5%] lg:bottom-[-10.5%]"
          src={SquareImg}
          alt="triangle-img"
        />
        <div className="container xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto px-5 xl:px-0">
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="sm:w-/12 md:w-10/12 lg:w-6/12 mx-auto">
              {/* <img className="w-full" src={AboutUsImg} alt="About-us-img" /> */}
              <Lottie
                loop
                animationData={about_animtion}
                play
                className="w-full"
              />
            </div>
            <div className="w-full md:w-10/12 lg:w-6/12">
              <div className="lg:pl-4 xl:pl-12  mt-6 lg:mt-0">
                <h2 className="text-red  font-medium text-[32px] md:text-[36px] lg:text-[40px] xl:text-lg ff_inter">
                  About <span className="font-semibold">Us</span>
                </h2>
                {/* <p className="ff_inter text-black3a text-xs md:text-base font-medium italic pt-1 sm:pt-2 leading-[150%]">
                  Dynamic Public Relations and its founder, Alee Opuiyo
                </p> */}
                <p className="ff_inter text-black3a text-xs md:text-base font-normal opacity-70 pt-2 sm:pt-3 xl:pt-3.5 leading-[174%]">
                  At Dynamic Public Relations, we pride ourselves on our
                  professional, strategic, and results-oriented personality.
                  With 16 years of experience in the field, we have gained
                  extensive knowledge of the media landscape, enabling us to be
                  a highly competent and skilled team. Our mission statement, "Crafting
                  Your Narrative, Amplifying Your Reach," highlights our
                  innovative approach to public relations, where we focus on
                  creating and sharing compelling narratives. We are confident,
                  proactive, and committed to delivering successful outcomes for
                  our clients.
                </p>
                <p className="ff_inter text-black3a text-xs md:text-base font-normal opacity-70 pt-2 leading-[174%] xl:pr-3">
                  Dynamic Public Relations is a dynamic, forward-thinking
                  company that is always seeking new opportunities and ways to
                  innovate in the field of public relations. We are confident,
                  energetic, and passionate about our work, and we take pride in
                  helping our clients achieve their goals. We believe in
                  building strong relationships with our clients and our
                  community, and we strive to be collaborative, respectful, and
                  inclusive in everything we do.
                </p>
                <a
                  className="no-underline"
                  href="https://calendly.com/dynamicpublicrelations"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <button className="z-20 ff_inter mt-4 md:mt-5 lg:mt-6 xl:mt-9 py-2.5 md:py-[10.5px] px-6 md:px-8 text-white rounded-full font-normal text-xs sm:text-sm bg-red border-solid border-[1px] border-red overflow-hidden hover:bg-white after:bg-white hover:text-black relative duration-300 ease-linear after:absolute after:w-full after:h-0 after:top-0 after:left-0 after:z-[-1] after:duration-300 after:ease-linear hover:after:h-full hover:after:bottom-0">
                    Make An Appointment
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutus;
