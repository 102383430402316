import Logo from "../../assets/images/svg/Group.svg";
import clndr from "../../assets/images/svg/calendar.svg";
import comntImg from "../../assets/images/svg/comment.svg";

export const pressSlider = [
  {
    pressImg: Logo,
    calendarImg: clndr,
    date: "May 3, 2023",
    commentImg: comntImg,
    comment: "02 Comments",
    loremBig: "W.E.A.L.T.H. Conversations Celebrating 50 Years of Hip Hop With Legendary Hip Hop Photographer Ernie Paniccioli",
    loremPara:
      "W.E.A.L.T.H. (Wellness. Edutainment – a fusion of education and entertainment - Arts. Lifestyle. Technology. Health.) Conversations, produced by Human Care Media, is celebrating 50 years of Hip Hop with Legendary Hip Hop Photographer Ernie Paniccioli on May 25 at the Jersey City Theater Center located at 165 Newark Ave (Barrow Street Entrance) Jersey City, New Jersey from 6pm – 9pm",
    readBtn: "Read More",
    link: "https://myemail.constantcontact.com/SAVE-THE-DATE---MAY-25.html?soid=1136977784788&aid=GBXI5eTXXTA"
  },
  {
    pressImg: "https://files.constantcontact.com/4df2829b801/3c08eac5-bc2d-45bb-ad9a-fb52ffa43f41.jpg?rdr=true",
    calendarImg: clndr,
    date: "February 17, 2023",
    commentImg: comntImg,
    comment: "02 Comments",
    loremBig: "Tech Executive and His Family File Suit Against the NYC Department of Education, Citing Racial Attacks",
    loremPara:
      "Founder/CEO of FinTech startup Bckers, Inc., Amos Winbush III and his wife, Tiffany Winbush, have filed a lawsuit against the New York City Department of Education (NYDOE) on behalf of their children...",
    readBtn: "Read More",
    link: "https://myemail.constantcontact.com/Dynamic-Public-Relations--LLC.html?soid=1136977784788&aid=ZKoPe0NSVCI"
  },
  {
    pressImg: Logo,
    calendarImg: clndr,
    date: "June 23, 2022",
    commentImg: comntImg,
    comment: "02 Comments",
    loremBig: "Commencement Speaker Gives $20,000 in Scholarships to the Eagle Academy for Young Men III of Southeast Queens and Three Top Students of the Graduating Class",
    loremPara:
      "Heart and Lung Transplant Surgeon and Director of Surgery for STAR (Specialized Transplant AI-Adapted Recovery) Teams, Hassan A. Tetteh, MD, MBA will serve as commencement speaker at the Eagle Academy for Young Men III of Southeast Queens’ graduation ceremony on June 24th",
    readBtn: "Read More",
    link: "https://myemail.constantcontact.com/Commencement-Speaker-Gives--20-000-to-the-Eagle-Academy-for-Young-Men-III-of-Southeast-Queens-and-Three-Top-Students-of-the-Grad.html?soid=1136977784788&aid=43_uqk1Hd0E"
  },
  {
    pressImg: Logo,
    calendarImg: clndr,
    date: "November 9, 2021",
    commentImg: comntImg,
    comment: "02 Comments",
    loremBig: "Dr. Hassan A. Tetteh Featured in The Wall Street Journal’s Weekend Interview Column for His Role as Health Mission Chief for the Pentagon’s Joint Artificial Intelligence Center",
    loremPara:
      "Dr. Hassan A. Tetteh, author of “The Art of Human Care” book series, was the featured subject of The Wall Street Journal’s Weekend Interview...",
    readBtn: "Read More",
    link: "https://conta.cc/3n0BPTl"
  },
  {
    pressImg: Logo,
    calendarImg: clndr,
    date: "October 19, 2021",
    commentImg: comntImg,
    comment: "02 Comments",
    loremBig: "Award-Winning Stage Play “Life Isn’t Fair” Taking the Stage at The MET Philadelphia on November 21",
    loremPara: "Life Isn’t Fair” embodies a mix of humor and drama as it follows Ricky Jones. Raised by his church-going grandparents following the death of his mother and sister, Ricky’s outrageous...",
    readBtn: "Read More",
    link: "https://conta.cc/3ZbuJMc"
  },
  {
    pressImg: Logo,
    calendarImg: clndr,
    date: "September 30, 2021",
    commentImg: comntImg,
    comment: "02 Comments",
    loremBig: "Former German President Publishes English Version of His Memoir - “All the Way Up, All the Way Down”",
    loremPara: "In the book, President Christian Wulff candidly narrates his turbulent time in office from 2010 to his resignation in 2012...",
    readBtn: "Read More",
    link: "https://conta.cc/40BN5qQ"
  },
  {
    pressImg: Logo,
    calendarImg: clndr,
    date: "August 17, 2021",
    commentImg: comntImg,
    comment: "02 Comments",
    loremBig: "Dr. Hassan A. Tetteh Expands Book Series With “The Art of Human Care with AI”",
    loremPara: "In The Art of Human Care with AI, Tetteh discusses the relationship between artificial intelligence and medicine and why AI has become personal for him...",
    readBtn: "Read More",
    link: "https://conta.cc/3LQcJDU"
  },
];

export const TheNewsslider = [
  {
    pressImg: "https://www.essence.com/wp-content/uploads/2023/03/IMG_3786-1920x1080.png?width=1280&lc=1678314026",
    loremBig: "Black Tech Founder And Wife Allege Children Faced Years Of Racism And Bullying In Lawsuit Against NYC School Board",
    loremPara:
      "Amos and Tiffany Winbush said their children “Have endured unimaginable racism, physical and emotional trauma” during their time at the peck slip school in new york city...",
    readBtn: "Read More",
    link: "https://www.essence.com/news/nyc-school-racism-lawsuit/",
    video: "",
  },
  {
    pressImg: "https://i.kinja-img.com/gawker-media/image/upload/c_fit,f_auto,g_center,q_60,w_965/a5140fc0c15f10846877a91f13e6f697.jpg",
    loremBig: "Black Exec In NYC Tells of His Kids Enduring Racist Bullying in the School",
    loremPara:
      "A tech executive sought legal action after ignored racist attacks against his children...",
    readBtn: "Read More",
    link: "https://www.theroot.com/black-exec-in-nyc-tells-of-his-kids-enduring-racist-bul-1850205009",
    video: "",
  },
  {
    pressImg: "https://thegrio.com/wp-content/uploads/2023/03/Winbushfamily.webp",
    loremBig: "Lawsuit says Black family \"endured unimaginable racism\" at NYC public schools",
    loremPara: "A lawsuit accuses the school of turning a blind eye to bullying, violence, trauma and dehumanization treatment of...",
    readBtn: "Read More",
    link: "https://thegrio.com/2023/03/15/public-schools-racism-black-family/",
    video: "",
  },
  {
    pressImg: "https://assets2.cbsnewsstatic.com/hub/i/r/2019/11/18/7352a120-0018-4b01-9ecb-b999aa401b88/thumbnail/1280x720/a6407980aa05c33cfbed2b770a7a6674/1118-ctm-schoolpushout-duncan-1979276-640x360.jpg",
    loremBig: "Documentary explores \"pattern of violence against black girls\" in schools",
    loremPara: "Lorem habitant viverra mauris pharetra amet sit sed duis augue. Vel sem phasellus egestas eu bibendum urna risus fames at. Eget sit lobortis tristique praesent mattis.",
    readBtn: "Read More",
    link: "https://www.cbsnews.com/news/documentary-pushout-explores-why-black-girls-are-punished-more-at-school-than-others/",
    video: "",
  },
  {
    pressImg: "https://i.kinja-img.com/gawker-media/image/upload/c_fit,f_auto,g_center,q_60,w_965/c1idwsu9sb2o3dsgdn6f.jpg",
    loremBig: "Ayanna Pressley Introduces Legislation to End the ‘Pushout’ of Black Girls",
    loremPara: "We are all magicians.This country practices a particular kind of wizardry that can make black votes disappear; turn a black radical into a whitewashed moderate ...",
    readBtn: "Read More",
    link: "https://www.cbsnews.com/news/documentary-pushout-explores-why-black-girls-are-punished-more-at-school-than-others/",
    video: "",
  },
  {
    pressImg: "https://madamenoire.com/wp-content/uploads/sites/9/2021/04/1618597829473.jpeg?strip=all&quality=80",
    loremBig: "She Tried It: The NakedFace Hair And Body Care",
    loremPara: "As the founder of The NakedFace — a brand born out of the hopes to help Black women like herself who’ve struggled with hyperpigmentation and sun damage, Tracy Rookard’s...",
    readBtn: "Read More",
    link: "https://madamenoire.com/1221222/stimulate-scalp-for-hair-growth/",
    video: "",
  }
];

export const customerSliderData = [
  {
    sliderHeading: "Nikki Fortune | Hustle A Novel |  Hustle the Musical",
    sliderImg: "https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T2/images/I/41BcqZLW52L._SX322_BO1,204,203,200_.jpg",
    sliderPara: `I had the privilege of working with Dynamic Public Relations to promote “Hustle the Musical.” 
      When Alee takes on a project she immerses herself into it and always goes the extra mile. She is passionate,
     professional and thoughtful in the management of her clients. I was also impressed by her reach and communication skills.
      Although her company is based in the Washington, D.C. metropolitan area, she has strong connections/contacts throughout the US. 
      I highly recommend Alee if you’re looking for a Public Relations firm that is dedicated to hard work and results.`,
  },
  {
    sliderHeading: "Omotunde \"Tunde\" Sowole-West, MPH Chief Executive Officer & Founder | Helping Us Grow Sexually (H.U.G.S) AASECT Professional Member",
    sliderImg: "https://img1.wsimg.com/isteam/ip/227a1f2d-f1f8-4fb9-98b1-e629c584a243/Helping_20Us_20Grow_20Sexually_final.png",
    // sliderImg: "https://img1.wsimg.com/isteam/ip/227a1f2d-f1f8-4fb9-98b1-e629c584a243/Helping_20Us_20Grow_20Sexually_final.png",
    sliderPara: `Having Dynamic Public Relations as a Public Relations representative has been such an amazing experience. 
    Alee is very responsive, listens to details and continuously follow-up and through on every single assignment.
    It was a pleasure having Alee as our Public Relations agent. Can't wait to work with  Dynamic Public Relations again!`
  },
  {
    sliderHeading: "Will Walters |  Founder & Publisher of Monarch Magazine Renaissance Media Group",
    sliderImg: "https://files.magzter.com/resize/magazine/1423261324/1549996812/view/3.jpg",
    sliderPara: `Alee is resourceful, efficient, well-connected, and more than capable of meeting deadlines.
    Unlike most publicists, she does not burn up your dollars with hours of discovery, or multiple meetings and calls. She gets the job done!"
    `,
  },
  {
    sliderHeading: "Angela Margaret Newsome | CEO, Co-Creator, Writer, Executive Producer of Civil Rights Diaries, LLC",
    sliderImg: "https://static.wixstatic.com/media/4a9f3f_e712c7167f3249fd909ef3c18521bbb6~mv2.png/v1/fit/w_2500,h_1330,al_c/4a9f3f_e712c7167f3249fd909ef3c18521bbb6~mv2.png",
    sliderPara: `Alee Opuiyo is a true superhero when it comes to Public Relations. She tames my chaos,
     and ensures our campaigns exceed expectations every time! From getting up-to-speed on the intricacies 
     of all five entities in Angela Margaret Newsome's brand portfolio - RPCard Foundation Partners Inc., 
     Civil Rights Diaries LLC, Okumura Music Group LLC, Author Margaret Writes -- she certainly knows her stuff! 
     uch creativity coupled with an abundance of experience truly makes me glad we have Alee as part of our team`,
  },
  {
    sliderHeading: "Aleasha A. | Rise Against Cancer",
    sliderImg: Logo,
    sliderPara: `I have had the pleasure of working with Dynamic Public Relations since 2016 (during my sarcoma cancer treatment community awareness event). 
    I first met Ms. Alee, the founder of Dynamic Public Relations, on social media and was impressed by her prompt response to my message.
    From the moment I began working with Ms. Alee, I was struck by her professionalism, 
    industry knowledge, and drive. Her follow-up skills are impeccable, 
    and she has an amazing ability to engage media outlets. I have personally witnessed her secure top-tier placements for her clients,
    which is a testament to her expertise and dedication. Ms. Alee has a compassionate, genuine, and caring spirit, but also possesses a 
    no-nonsense bite when necessary.  `,
  },
  {
    sliderHeading: "Reniece Goodwine  | Renco Studios",
    sliderImg: Logo,
    sliderPara: `I have worked with Alee of Dynamic Public Relations for over 15 years.
    Alee is simply the best in the business.She provides wisdom and knowledge well beyond her years.
    For one of my events, I was fascinated to witness how amazingly well-connected she is in the world of media!
    I was able to experience an exciting whirlwind of interviews with her presence behind the scenes.
    I am an extreme introvert and Alee continued to coach me every step of the way.
    I have become more comfortable with public speaking as time has passed. 
    I have learned so much from Alee over the years and I am deeply grateful to be connected with such an incredible, hard-working business woman.
    It has been a blessing to experience the services from Dynamic Public Relations.Alee is worth her weight in gold!   
    `,
  },
  {
    sliderHeading: "Lance A. Clarke, Esq. | Hamilton Clarke, LLP",
    sliderImg: "https://files.constantcontact.com/4df2829b801/3c08eac5-bc2d-45bb-ad9a-fb52ffa43f41.jpg?rdr=true",
    sliderPara: `Alee is extremely responsive and effective. Day or night she can be reached with a viable solution with little turnaround time. 
    Both her extensive network, coupled with her incredible work ethic, made for a successful and pleasant work relationship.
     We are extremely appreciative and grateful for her hard work. `

  }
];