import React from "react";
import {
  LocationIcon,
  PhoneIcon,
  // SearchIcon,
  // ShopsyIcon,
  // SlashIcon,
  TymerIcon,
} from "./common/Icons";

const TopBar = () => {
  return (
    <>
      <div className="bg-blue w-full hidden xl:block max-h-[80px]">
        <div className="container xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto pt-[18px] pb-[23px] sm:px-8 xl:px-0">
          <div className="flex justify-between items-center">
            <div className="flex">
              <div className="flex topbar_icon cursor-pointer">
                <PhoneIcon />
                <div className="pl-[12px]">
                  <p className="text-xs font-normal ff_inter text-white mb-0">
                    Call Today:
                  </p>
                  <a
                    href="tel:+1 240 393 3100"
                    className="text-xs font-semibold ff_inter text-white"
                  >
                    +1 240 393 3100
                  </a>
                </div>
              </div>
              <div className="flex ml-[30px] topbar_icon cursor-pointer">
                <TymerIcon />
                <div className="pl-[12px]">
                  <a
                    href="mailTo:alee@dynamicpublicrelations.net"
                    className="text-xs font-normal ff_inter text-white"
                  >
                    alee@dynamicpublicrelations.net
                  </a>
                  <p className="text-xs font-semibold ff_inter text-white mb-0">
                    Daily 8.00 AM - 5.00 PM
                  </p>
                </div>
              </div>
              <div className="flex ml-[30px] topbar_icon cursor-pointer">
                <LocationIcon />
                <div className="pl-[12px]">
                  <p className="text-xs font-normal ff_inter text-white mb-0">
                    Address Center:
                  </p>
                  <p className="text-xs font-semibold ff_inter text-white mb-0">
                    MARYLAND | WASHINGTON, DC | VIRGINIA
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBar;
