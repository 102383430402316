import React, { useState } from "react";
import Lottie from "react-lottie";
import serviceImg from "../assets/lottie/service.json";
import ServiceModal from "./ServiceModal";

const NewService2 = () => {
  const serviceLottie = {
    loop: true,
    autoplay: true,
    animationData: serviceImg,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const mediaText = `We craft compelling stories for our clients and secure immediate media placement through effective press releases and media advisories. Our expertise in media campaigns and understanding the language of the media guarantees journalistically appealing and interesting content for maximum exposure. We act as a liaison between our clients and the media, securing print, electronic, television, and radio coverage. With Dynamic Public Relations, our clients receive a comprehensive and effective media campaign that brings their stories to life`;

  const journalismText = `Our firm is able to handle, with tact and professionalism, crisis management situations on behalf of our clients. It is always important to have situations of this nature handled in a timely manner to get ahead of an unfavorable narrative. Doing so, proactively, aids in the reduction of “collateral manage.” Our firm specializes in identifying and responding to anything the poses a threat to the integrity of our client’s reputation and overall profile.`;

  const marketingText =
    "We help our clients with strategic planning to market their products and services in a way that yields maximum exposure and market leverage. Our research identifies appropriate demographic groups advertising efforts should target - mainly through in-depth corporate planning and development that complement consumer attitudes and preferences.";

  const featureText = `Dynamic Public Relations creates and drafts an all-inclusive media/press campaign plan for our clients. In this case, Dynamic Public Relations would not execute the campaign, but would instead craft a blueprint for an effective media/press campaign. These plans would outline detailed recommendations for the successful execution of a campaign. Strategic planning would be used to develop an easy-to follow outline.`;

  const [isOpen, setIsOpen] = useState(false);
  const [activeModalText, setActiveModalText] = useState("");

  function closeModal() {
    setIsOpen(false);
  }

  function handleOpenModal(text) {
    setIsOpen(true);
    setActiveModalText(text);
  }

  return (
    <>
      <div className="bg-[#F9F9F9] sm:pt-12 pt-10 relative" id="services">
        <div className="container xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto px-5 xl:px-0 relative">
          <div>
            <h2 className="text-black3a text-center font-medium text-[32px] md:text-[36px] lg:text-[40px] xl:text-lg ff_inter sm:pb-10 pb-5">
              Services
            </h2>
            <div className="sm:flex lg:max-w-[60%] md:max-w-[70%] mx-auto lg:pt-4 justify-between">
              {/* Media text start ------------------------------------------------ */}
              <div className="md:w-1/2 flex flex-col lg:pr-20 md:pr-10 justify-center sm:max-w-[290px] md:max-w-[360px]">
                <div className="text_overflow_dots02">
                  <h3 className="ff_inter lg:text-[24px] md:text-[20px] sm:text-[22px] text-[20px] font-[500] text-[#DFA111]  whitespace-nowrap">
                    Media/Press
                  </h3>
                  <h3 className="ff_inter lg:text-[24px] md:text-[20px] sm:text-[22px] text-[20px] font-[500] text-[#DFA111]  whitespace-nowrap">
                    Press Campaigns
                  </h3>
                  <p className="ff_inter pt-1 lg:text-[16px] text-[15px] font-normal text-[#3A3A3A] opacity-80">
                    {/* <span className="mr-1">
                      We craft compelling stories for our clients and secure
                      immediate media placement through effective press releases
                      and media...
                    </span> */}
                    {mediaText}
                  </p>
                </div>
                <button
                  onClick={() => handleOpenModal(mediaText)}
                  className="text-[#CF1B1B] text-start ff_inter"
                >
                  Read more
                </button>
              </div>
              {/* Crisis Management  text start ----------------------------------- */}
              <div className="md:w-1/2 lg:pl-20 md:pl-10 sm:max-w-[290px] md:max-w-[360px] pt-4 sm:pt-0">
                <div className="text_overflow_dots02">
                  <h3 className="ff_inter lg:text-[24px] md:text-[20px] sm:text-[22px] text-[20px] font-[500] text-[#CF1B1B] whitespace-nowrap">
                    Crisis Management
                  </h3>
                  <p className="ff_inter pt-1 lg:text-[16px] text-[15px] font-normal text-[#3A3A3A] opacity-80">
                    {journalismText}
                    {/* <span className="mr-1">
                    Our firm is able to handle, with tact and professionalism,
                    crisis management situations on behalf of our clients...
                  </span> */}
                  </p>
                </div>
                <button
                  onClick={() => handleOpenModal(journalismText)}
                  className="text-[#CF1B1B] text-start ff_inter"
                >
                  Read more
                </button>
              </div>
            </div>
            <div className="sm:flex md:pt-8 sm:pt-4 justify-between">
              {/* Marketing text start ------------------------------------------- */}
              <div className="md:w-1/4 flex flex-col pt-4 justify-center sm:max-w-[290px] md:max-w-[250px] sm:pb-8 lg:pb-0">
                <div className="text_overflow_dots02">
                  <h3 className="ff_inter lg:text-[24px] md:text-[20px] sm:text-[22px] text-[20px] font-[500] text-[#1599BC]  whitespace-nowrap">
                    Marketing
                  </h3>
                  <p className="ff_inter pt-1 lg:text-[16px] text-[15px] font-normal text-[#3A3A3A] opacity-80">
                    {marketingText}
                    {/* <span className="mr-1">
                    We help our clients with strategic planning to market their
                    products and services in a way that yields...
                  </span> */}
                  </p>
                </div>
                <button
                  onClick={() => handleOpenModal(marketingText)}
                  className="text-[#CF1B1B] text-start ff_inter"
                >
                  Read more
                </button>
              </div>
              {/* IMAGE ---------------------------------------------------------- */}
              <div className="w-1/2 md:flex flex-col justify-end hidden">
                <div className="text-center px-8">
                  <Lottie options={serviceLottie} width="100%" />
                </div>
              </div>
              {/* Feature text start --------------------------------------------- */}
              <div className="md:w-1/4 flex flex-col pt-4 justify-center sm:max-w-[290px] md:max-w-[250px] pb-8 lg:pb-0">
                <div className="text_overflow_dots02">
                  <h3 className="ff_inter lg:text-[24px] md:text-[20px] sm:text-[22px] text-[20px] font-[500] text-[#5114AD]  whitespace-nowrap">
                    Media/Press
                  </h3>
                  <h3 className="ff_inter lg:text-[24px] md:text-[20px] sm:text-[22px] text-[20px] font-[500] text-[#5114AD]  whitespace-nowrap">
                   Campaign Plans
                  </h3>
                  <p className="ff_inter pt-1 lg:text-[16px] text-[15px] font-normal text-[#3A3A3A] opacity-80">
                    {featureText}
                    {/* <span className="mr-1">
                    Dynamic Public Relations creates and drafts an all-inclusive
                    media/press campaign plan for our clients...
                  </span> */}
                  </p>
                </div>
                <button
                  onClick={() => handleOpenModal(featureText)}
                  className="text-[#CF1B1B] text-start ff_inter"
                >
                  Read more
                </button>
              </div>
            </div>
            <div className="text-center sm:px-8 sm:hidden">
              <Lottie options={serviceLottie} width="100%" />
            </div>
          </div>
        </div>
      </div>

      <ServiceModal
        activeModalText={activeModalText}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    </>
  );
};

export default NewService2;
