import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import { customerSliderData } from "./common/Helper";
import triangleIcon from "../assets/images/png/about-triangle-img.png";
import triangleIconBorder from "../assets/images/png/about-triangle-border-img.png";
import CustomerCard from "./CustomerCard";
import CustomerModal from "./common/CustomerModal";

const Customer = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [activeText, setActiveText] = useState(
    customerSliderData[0].sliderPara
  );

  function closeModal() {
    setIsOpen(false);
  }
  function handleOpenModal() {
    setIsOpen(true);
  }

  const settings = {
    responsive: [
      {
        breakpoint: 1279.98,
        settings: {
          slidesToShow: 3,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1023.98,
        settings: {
          slidesToShow: 4,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 766.98,
        settings: {
          slidesToShow: 3,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 638.98,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        },
      },
    ],
    afterChange: (current, next) => {
      console.log("current", current);
      setActiveText(customerSliderData[current].sliderPara);
    },
  };
  return (
    <>
      <div className="relative customer-slider" >
        <div className="triangle-parent hidden sm:block">
          <img
            className="absolute right-[9%] top-[15%] w-[25px] md:w-[46px] rotate md:right-[2%]  lg:top-[6%] z-10 animate-[wiggle_infinite_linear_2s]"
            src={triangleIcon}
            alt="triangleIcon"
          />
          <img
            className="absolute right-[11%] rotate w-[25px] md:w-[46px] top-[15%] md:right-[2.6%] lg:top-[6.5%] "
            src={triangleIconBorder}
            alt="triangleIconBorder"
          />
        </div>
        <div className="container xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto px-5 xl:px-0 pt-[56px] pb-[56px] sm:pt-[80px] sm:pb-[64px] lg:py-[110px] xl:pt-[174px] xl:pb-[166px]">
          <h2 id="clients" className="text-black3a font-medium text-[32px] md:text-[36px] lg:text-[40px] xl:text-lg ff_inter mb-[32px] md:mb-[60px] lg:mb-[80px] text-center leading-[110.5%]">
            What our <span className="text-blue">Clients say</span>
          </h2>
          <div className="sm:mb-[22px] lg:mb-[-233px] 2xl:mb-[-253px] relative" >
            <Slider
              asNavFor={nav2}
              ref={slider1}
              fade={true}
              arrows={false}
              // autoplay={true}
              autoplaySpeed={8000}
              speed={800}
            >
              {customerSliderData.map((item, index) => (
                <CustomerCard
                  item={item}
                  key={index}
                  index={index}
                  handleOpenModal={handleOpenModal}
                />
              ))}
            </Slider>
          </div>
          <div className="lg:pr-[0px] mx-auto sm:mx-[0] max-w-[320px] sm:max-w-[100%] lg:max-w-[588px] xl:max-w-[748px] 2xl:max-w-[860px] lg:ml-[auto] lg:pl-[8px] lg:mb-[22px]">
            <Slider
              {...settings}
              asNavFor={nav1}
              ref={slider2}
              slidesToShow={4}
              swipeToSlide={true}
              focusOnSelect={true}
              infinite={true}
              arrows={true}
              // autoplay={true}
              autoplaySpeed={8000}
              speed={800}
              // cssEase="linear"
              pauseOnHover={true}
            >
              {customerSliderData.map((item, index) => (
                <div key={index}>
                  <img
                    className="rounded-[8px] min-h-[188px] sm:min-h-[198px] max-h-[188px] sm:max-h-[198px] lg:min-h-[228px] lg:max-h-[228px] 2xl:min-h-[248px] 2xl:max-h-[248px] ml-[auto] mr-[auto] lg:mr-[0] sm:pl-[8px] object-contain max-w-[144px] lg:min-w-[176px] sm:max-w-[176px]"
                    src={item.sliderImg}
                    alt="overviewSliderImg"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <CustomerModal
        isOpen={isOpen}
        closeModal={closeModal}
        activeText={activeText}
      />
    </>
  );
};

export default Customer;
