import React from "react";
import loader from "../../assets/images/png/preloader.png";

const PreLoader = () => {
  return (
    <>
      <div className="loader-fullscreen">
        <img className="loader-img" src={loader} alt="loaderImg" />
      </div>
    </>
  );
};

export default PreLoader;
