import React from "react";
import hero_animtion from "../assets/lottie/hero_animation.json";
import red_arrow from "../assets/lottie/Arrow_red.json";
import Lottie from "react-lottie-player";

const Hero = () => {
  return (
    <>
      <div className=" relative" id="home">
        <div className="container xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto px-5 xl:px-0">
          <div className="flex flex-col-reverse lg:flex-row justify-between items-center lg:min-h-[calc(100vh-168px)] md:mt-5 lg:mt-0">
            <div className="w-full md:w-10/12 lg:w-6/12 xl:col-start-auto ">
              <div className="mt-6 lg:mt-0 w-full xl:w-[561px] relative">
                <div className="hidden md:block w-[55px] absolute top-0 lg:top-[-22%] xl:top-[-10%] md:top-[-18%] md:left-[-7%] lg:left-[-5%] xl:left-[-15%]  transform_rotate_red ">
                  <Lottie
                    loop
                    animationData={red_arrow}
                    play
                    className="w-full"
                  />
                </div>
                <h1 className="text-red font-bold leading-[110.47%] text-[32px] md:text-[36px] lg:text-[38px] xl:text-[45px] ff_inter">
                  Dynamic Public Relations
                </h1>

                <p className="ff_inter text-black3a text-xs md:text-base font-normal opacity-70 pt-3 xl:pt-3.5 leading-[174%] mb-4 sm:mb-6 lg:mb-12">
                  Dynamic Public Relations is a public relations firm dedicated
                  to helping our clients communicate their messages to the
                  world. We strive to be innovative, strategic, and effective in
                  everything we do, and we believe that strong communication can
                  build bridges between individuals, organizations, and
                  communities.
                  <br />
                  <br />
                  Dynamic Public Relations, Inc. is
                  <span className="font-bold mx-1">MBE/DBE/SBE/SBA</span>
                  Certified Business
                </p>

                {/* <button className="z-20 mr-4 ff_inter py-2.5 md:py-[10.5px] px-6 md:px-8 text-white rounded-full font-normal text-base sm:text-sm bg-red border-solid border-[1px] border-red overflow-hidden hover:bg-white after:bg-white hover:text-black relative duration-300 ease-linear after:absolute after:w-full after:h-0 after:top-0 after:left-0 after:z-[-1] after:duration-300 after:ease-linear hover:after:h-full hover:after:bottom-0">
                  Make An Appointment
                </button> */}
                <a href="#about">
                  <button className="mt_xs_16 z-20 ff_inter py-2.5 md:py-[10.5px] px-6 md:px-8 text-black3a rounded-full font-normal text-base sm:text-sm border-solid border-[1px] border-[#B3B3B3] hover:border-red overflow-hidden hover:bg-red after:bg-red hover:text-white relative duration-300 ease-linear after:absolute after:w-full after:h-0 after:top-0 after:left-0 after:z-[-1] after:duration-300 after:ease-linear hover:after:h-full hover:after:bottom-0">
                    Learn More
                  </button>
                </a>
              </div>
            </div>
            <div className="mt-12 lg:mt-0 md:w-10/12 lg:w-6/12 xl:col-start-auto ">
              <div className="lg:max-w-[500px] xl:max-w-full xl:w-[580px] 2xl:w-[700px] lg:absolute lg:right-0 xl:right-[2%] 2xl:right-[5%] top-[50%] lg:translate-y-[-50%]">
                <Lottie
                  loop
                  animationData={hero_animtion}
                  play
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
