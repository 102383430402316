import "./App.css";
import Hero from "./component/Hero";
import Header from "./component/Header";
import Customer from "./component/Customer";
import Aboutus from "./component/Aboutus";
import Footer from "./component/Footer";
import PressRelease from "./component/PressRelease";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TopBar from "./component/TopBar";
import TheNews from "./component/TheNews";
import BackToTop from "./component/common/BackToTopButton";
import PreLoader from "./component/common/PreLoader";
import { useEffect, useState } from "react";
import NewService2 from "./component/NewService2";

function App() {
  const [loading, setLoading] = useState(true);
  // FOR FULL SCREEN LOADING
  useEffect(() => {
    document.body.classList.add("overflow-hidden-while-loading");
    setTimeout(() => {
      setLoading(false);
      document.body.classList.remove("overflow-hidden-while-loading");
    }, 3000);
  }, []);
  return (
    <>
      {loading && <PreLoader />}
      <TopBar />
      <Header />
      <Hero />
      <Aboutus />
      <NewService2 />
      <Customer />
      <TheNews />
      <PressRelease />
      <Footer />
      <BackToTop />
    </>
  );
}

export default App;
