import React from "react";
import {
  Facebook,
  Twitter,
  Insta,
  FooterLogo,
  PhoneIcon,
  TymerIcon,
} from "../component/common/Icons";

const Footer = () => {
  return (
    <>
      <div className=" bg_blue bg-black16">
        <div className="container xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto px-5 xl:px-0">
          <div className="flex flex-col lg:flex-row justify-between py-12 md:py-10 lg:pt-[53px] lg:pb-[43px]">
            <div className="w-full lg:w-7/12 mx-auto">
              <div className="flex flex-col sm:flex-row justify-between">
                <div className="flex flex-col pb-5 sm:pb-0">
                  <span className="w-[182px] height-[49px] footer_icon">
                    <FooterLogo />
                  </span>
                  <p className="text-white ff_inter font-normal text-xs opacity-80 leading-[174%] pt-2.5">
                    Crafting Your Narrative, Amplifying Your Reach
                  </p>
                  <div className="flex pt-4 md:pt-5 gap-1">
                 <a
                    href="https://www.facebook.com/Dynamicpublicrelations"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <Facebook />
                  </a>
                  <a
                    href="https://twitter.com/Dynamicprpro"
                    target={"_blank"}
                    className="ml-1.5"
                    rel="noreferrer"
                  >
                    <Twitter />
                  </a>
                  <a
                    href="https://www.instagram.com/DynamicPublicRelations/"
                    target={"_blank"}
                    className="ml-1.5"
                    rel="noreferrer"
                  >
                    <Insta />
                  </a>
                </div> 
                </div>
                <div className="flex flex-col lg:ml-[16px]">
                  <div className="flex topbar_icon cursor-pointer">
                    <PhoneIcon />
                    <div className="pl-[12px]">
                      {/* <p className="text-xs font-normal ff_inter text-white mb-0">
                        Call Today:
                      </p> */}
                      <a
                        href="tel:+1 240 393 3100"
                        className="text-xs font-semibold ff_inter text-white"
                      >
                        +1 240 393 3100
                      </a>
                    </div>
                  </div>
                  <div className="flex mt-[20px] sm:mt-[24px] topbar_icon cursor-pointer">
                    <TymerIcon />
                    <div className="pl-[12px]">
                      <a
                        href="mailTo:alee@dynamicpublicrelations.net"
                        className="text-xs font-normal ff_inter text-white"
                      >
                        alee@dynamicpublicrelations.net
                      </a>
                      <p className="text-xs font-semibold ff_inter text-white mb-0">
                        Daily 8.00 AM - 5.00 PM
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-10/12 md:w-5/12 ml-0 sm:mx-auto whitespace-nowrap mt-[9px] lg:mt-[0px]">
              <div className="flex justify-between lg:justify-end">
                <div className="sm:w-4/12 lg:w-2/12 flex flex-col items-center sm:mr-[30px] lg:mr-[70px]">
                  <div>
                    <ul>
                      <li className="mt-[11px] lg:mt-[0]">
                        <a
                          href="#home"
                          className="ff_inter font-normal text-white text-xs opacity-80 transition-all ease-linear duration-300 hover:opacity-100 hover:text-blue"
                        >
                          Home
                        </a>
                      </li>
                      <li className="mt-[14px]">
                        <a
                          href="#about"
                          className="ff_inter font-normal text-white text-xs opacity-80 transition-all ease-linear duration-300 hover:opacity-100 hover:text-blue"
                        >
                          About Us
                        </a>
                      </li>
                      <li className="mt-[14px]">
                        <a
                          href="#services"
                          className="ff_inter font-normal text-white text-xs opacity-80 transition-all ease-linear duration-300 hover:opacity-100 hover:text-blue"
                        >
                          Services
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="sm:w-4/12 lg:w-2/12 flex flex-col items-center">
                  <div>
                    <ul>
                      <li className="mt-[11px] lg:mt-[0]">
                        <a
                          href="#customers"
                          className="ff_inter font-normal text-white text-xs opacity-80 transition-all ease-linear duration-300 hover:opacity-100 hover:text-blue"
                        >
                          Customers
                        </a>
                      </li>
                      <li className="mt-[14px]">
                        <a
                          href="#news"
                          className="ff_inter font-normal text-white text-xs opacity-80 transition-all ease-linear duration-300 hover:opacity-100 hover:text-blue"
                        >
                          News
                        </a>
                      </li>
                      <li className="mt-[14px]">
                        <a
                          href="#press"
                          className="ff_inter font-normal text-white text-xs opacity-80 transition-all ease-linear duration-300 hover:opacity-100 hover:text-blue"
                        >
                          Press
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-blue py-4 text-center">
          <p class="font-normal ff_inter text-xs text-white w-[262px] sm:w-full m-auto sm:m-0">
            © Copyright Dynamic Public Relations. | All Rights Reserved. |
            Powered by <a href="https://ibeify.io/"> © Ibeify</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;